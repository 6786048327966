import useGetBillingAccounts from "@/api/analytics/hooks/useGetBillingAccounts";
import useGetDimensionalNetCostByBillingAccountIDs from "@/api/analytics/hooks/useGetDimensionalNetCostByBillingAccountIDs";
import useGetNetCostByBillingAccountIDs from "@/api/analytics/hooks/useGetNetCostByBillingAccountIDs";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import Select from "@/ui-lib/components/Select";
import { useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { TimeGranularity } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { endOfMonth, isBefore, startOfHour, startOfMonth } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { v4 as uuidv4 } from "uuid";
import { DateHelper } from "../../../lib/dates";
import { AlertType, postAlert } from "../../../utils/alerts";
import { getUTCMonthString } from "../../../utils/dates";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import useCreateRampPlan from "../hooks/useCreateRampPlan";
import useDeleteRampPlan from "../hooks/useDeleteRampPlan";
import useGetRampPlansByTenantID from "../hooks/useGetRampPlansByTenantID";
import useUpdateRampPlan from "../hooks/useUpdateRampPlan";
import { NetCostByRampPlanID } from "../types";
import {
  getComparisonStringForRampPlans,
  getDeltaBetweenRampPlans,
  getStartOfRampPlan,
} from "../utils/rampPlans";
import RampPlanChartSection from "./RampPlanChartSection";
import RampPlanConfigurationSection from "./RampPlanConfigurationSection";
import RampPlanDimensionalSpendChart from "./RampPlanDimensionalSpendChart";
import RampPlansTable from "./RampPlansTable";
import RampPlanTableSection, {
  RampPlansTableSection,
} from "./RampPlansTableSection";

type CSVNetCostConfig = {
  id: string;
  billingAccountIDs: string[];
  dateRange: Date[];
};

interface State {
  actualDimensionalSpendQueryListForCSV: CSVNetCostConfig[];
  configurationBillingAccountIDs: string[] | undefined;
  configurationStartDate: string | undefined;
  fetchHistoricalCostData: boolean;
  selectedMonthForDimensionalChart: string;
  isCopy: boolean;
}

type Interaction =
  | RampPlanConfigurationSection.Interaction
  | RampPlanDimensionalSpendChart.Interaction
  | RampPlansTableSection.Interaction
  | RampPlansTable.Interaction;

const defaultHistoricalCostData = [];
const newDate = new Date();

export default function RampPlansContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const now = new DateHelper();

  //
  // Search Params
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    pricing: withDefault(StringParam, "list"),
    selectedRampPlanIDToDisplay: StringParam,
    selectedRampPlanIDToEdit: StringParam,
    showCreateForm: withDefault(BooleanParam, false),
  });

  const selectedPricingDataKey =
    searchParamState.pricing === "custom" && gatekeeper.canApplyCustomPricing
      ? "customNetCost"
      : "netCost";

  //
  // State
  //

  const [state, setState] = useState<State>({
    actualDimensionalSpendQueryListForCSV: [],
    configurationBillingAccountIDs: undefined,
    configurationStartDate: undefined,
    fetchHistoricalCostData: false,
    selectedMonthForDimensionalChart: getUTCMonthString(
      now.firstOfLastMonth().toISOString()
    ),
    isCopy: false,
  });
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: rampPlanData,
    isLoading: isLoadingRampPlans,
    isFetched: isRampPlansFetched,
    refetch: refetchRampPlans,
  } = useGetRampPlansByTenantID(authenticatedUser.tenant.fsDocID);

  const rampPlans = rampPlanData?.map((rampPlan) => {
    return {
      ...rampPlan,
      commitments:
        rampPlan.commitments.map((commitment) => ({
          ...commitment,
          _id: uuidv4(),
        })) || [],
    };
  });

  //
  // Mutations
  //

  const { mutate: createRampPlan, isPending: isCreatingRampPlan } =
    useCreateRampPlan({
      onSuccess: () => {
        refetchRampPlans();
        handleCloseForm();

        postAlert({
          message: copyText.successfullyCreatedRampPlan,
          type: AlertType.SUCCESS,
        });
      },
      onError: () => {
        postAlert({
          message: copyText.errorCreatingRampPlan,
          type: AlertType.ERROR,
        });
      },
    });

  const { mutate: updateRampPlan, isPending: isUpdatingRampPlan } =
    useUpdateRampPlan({
      onSuccess: () => {
        refetchRampPlans();
        handleCloseForm();

        postAlert({
          message: copyText.successfullyUpdatedRampPlan,
          type: AlertType.SUCCESS,
        });
      },
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingRampPlan,
          type: AlertType.ERROR,
        });
      },
    });

  const { mutate: deleteRampPlan, isPending: isDeletingRampPlan } =
    useDeleteRampPlan({
      onSuccess: () => {
        refetchRampPlans();
        handleCloseForm();

        postAlert({
          message: copyText.successfullyDeletedRampPlan,
          type: AlertType.SUCCESS,
        });
      },
    });

  //
  // Side Effects
  //

  const selectedRampPlan = useMemo(() => {
    if (!rampPlans) return;

    return rampPlans.find(
      (rampPlan) => rampPlan.id === searchParamState.selectedRampPlanIDToDisplay
    );
  }, [
    rampPlans ? getComparisonStringForRampPlans(rampPlans) : "",
    searchParamState.selectedRampPlanIDToDisplay,
  ]);

  //
  // Analytics Data Fetching
  //

  const { data: billingAccounts = [], isLoading: isLoadingBillingAccounts } =
    useGetBillingAccounts(
      [now.nDaysAgo(365), now.date], // any account with spend in the last 365 days - consider merging with msp-provided mapping,
      {
        enabled: gatekeeper.canListRampPlans && gatekeeper.hasCloudIntergation,
      }
    );

  const [
    { data: actualsForSelected = [], isLoading: isLoadingActualsForSelected },
  ] = useGetNetCostByBillingAccountIDs(
    [
      {
        billingAccountIDs: selectedRampPlan?.billingAccountIDs ?? [],
        dateRange: [
          ...(selectedRampPlan
            ? [new Date(getStartOfRampPlan(selectedRampPlan?.commitments))]
            : []),
          startOfHour(now.lastDayLastMonth()),
        ],
      },
    ],
    { enabled: selectedRampPlan !== undefined }
  );

  const [
    {
      data: actualsForConfigurationView = [],
      isLoading: isLoadingActualsForConfigurationView,
    },
  ] = useGetNetCostByBillingAccountIDs(
    [
      {
        billingAccountIDs: state.configurationBillingAccountIDs ?? [],
        dateRange: [
          ...(state.configurationStartDate
            ? [new Date(state.configurationStartDate)]
            : []),
          startOfHour(now.lastDayLastMonth()),
        ],
      },
    ],
    {
      enabled:
        state.configurationBillingAccountIDs !== undefined &&
        state.configurationStartDate !== undefined &&
        gatekeeper.hasGCPIntegration,
    }
  );

  const [
    {
      data: historicalCostData = defaultHistoricalCostData,
      isLoading: isLoadingHistoricalCostData,
    },
  ] = useGetDimensionalNetCostByBillingAccountIDs(
    [
      {
        billingAccountIDs: state.configurationBillingAccountIDs ?? [],
        dateRange: [new Date(state.configurationStartDate ?? ""), newDate],
        granularity: TimeGranularity.MONTH,
      },
    ],
    { enabled: state.fetchHistoricalCostData }
  );

  const [
    {
      data: actualDimensionalsForSelected = [],
      isLoading: isLoadingActualDimensionalsForSelected,
    },
  ] = useGetDimensionalNetCostByBillingAccountIDs(
    [
      {
        billingAccountIDs: selectedRampPlan?.billingAccountIDs ?? [],
        dateRange: [
          startOfMonth(
            new Date(getUTCMonthString(state.selectedMonthForDimensionalChart))
          ),
          endOfMonth(
            new Date(getUTCMonthString(state.selectedMonthForDimensionalChart))
          ),
        ],
        granularity: TimeGranularity.DAY,
      },
    ],
    {
      enabled: selectedRampPlan !== undefined && isRampPlansFetched,
    }
  );

  const rampPlanCSVQueryResultList =
    useGetDimensionalNetCostByBillingAccountIDs(
      state.actualDimensionalSpendQueryListForCSV.map((query) => ({
        billingAccountIDs: query.billingAccountIDs,
        dateRange: query.dateRange,
        granularity: TimeGranularity.MONTH,
      })),
      {
        enabled: state.actualDimensionalSpendQueryListForCSV.length > 0,
      }
    );

  const previousNetCostQueryResultList = useGetNetCostByBillingAccountIDs(
    rampPlans
      ? rampPlans.map((rampPlan) => ({
          billingAccountIDs: rampPlan.billingAccountIDs,
          dateRange: [
            new Date(getStartOfRampPlan(rampPlan.commitments)),
            startOfHour(now.lastDayLastMonth()),
          ],
        }))
      : [],
    {
      enabled: rampPlans !== undefined,
    }
  );

  const isLoadingPreviousNetCostByRampPlan =
    previousNetCostQueryResultList.some((query) => query.isLoading);

  // Used to figure out all derived projections in the table
  const previousNetCostByRampPlanID = rampPlans
    ? previousNetCostQueryResultList.reduce(
        (accum: NetCostByRampPlanID, res, i) => {
          const rampPlanID = rampPlans[i]?.id;

          res.data?.forEach((entry) => {
            const netCost = entry[selectedPricingDataKey];

            const date = entry.date;

            if (accum[rampPlanID]) {
              accum[rampPlanID].push({ date, netCost });
            } else {
              accum[rampPlanID] = [{ date, netCost }];
            }
          });
          return accum;
        },
        {}
      )
    : {};

  // this is used so that csv components don't remount on form close
  function clearCSVState() {
    mergeState({ actualDimensionalSpendQueryListForCSV: [] });
  }

  //
  // Interaction Handlers
  //

  function handleCloseForm() {
    mergeState({
      configurationBillingAccountIDs: undefined,
      configurationStartDate: undefined,
      fetchHistoricalCostData: false,
      isCopy: false,
    });

    setSearchParamState({
      selectedRampPlanIDToEdit: null,
      showCreateForm: false,
    });
  }

  function handleOpenCreateForm() {
    clearCSVState();
    setSearchParamState({
      selectedRampPlanIDToEdit: null,
      showCreateForm: true,
    });
  }

  function handleOpenEditForm(rampPlanID) {
    clearCSVState();
    setSearchParamState({
      selectedRampPlanIDToEdit: rampPlanID,
    });
  }

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case RampPlanConfigurationSection.INTERACTION_BACK_BUTTON_CLICKED: {
        handleCloseForm();
        return;
      }

      case RampPlanConfigurationSection.INTERACTION_BILLING_ACCOUNT_ID_CHANGED: {
        mergeState({
          configurationBillingAccountIDs: interaction.billingAccountIDs,
        });
        return;
      }

      case RampPlanConfigurationSection.INTERACTION_CREATE_SUBMIT_BUTTON_CLICKED: {
        createRampPlan({
          tenantID: authenticatedUser.tenant.fsDocID,
          ...interaction.rampPlan,
        });
        activityTracker.captureAction(actions.CLICK_RAMP_PLAN_CREATE);
        return;
      }

      case RampPlanConfigurationSection.INTERACTION_POPULATE_HISTORICAL_SPEND_DATA_TOGGLED: {
        mergeState({
          configurationBillingAccountIDs: interaction.billingAccountIDs,
          configurationStartDate: interaction.startDate,
          fetchHistoricalCostData: interaction.checked,
        });
        return;
      }

      case RampPlanConfigurationSection.INTERACTION_START_DATE_CHANGED: {
        mergeState({ configurationStartDate: interaction.startDate });
        return;
      }

      case RampPlanConfigurationSection.INTERACTION_UPDATE_SUBMIT_BUTTON_CLICKED: {
        const {
          hasAnythingChanged,
          breakpointsToAdd,
          breakpointsToUpdate,
          breakpointsWithVersionsToAdd,
          rampPlanToUpdate,
        } = getDeltaBetweenRampPlans(
          interaction.existingRampPlan,
          interaction.updatedRampPlan
        );

        if (!hasAnythingChanged) return;

        updateRampPlan({
          rampPlanID: interaction.existingRampPlan.id,
          breakpointsToAdd,
          breakpointsToUpdate,
          breakpointsWithVersionsToAdd,
          ...rampPlanToUpdate,
        });
        return;
      }

      case RampPlanDimensionalSpendChart.INTERACTION_MONTH_CHANGED: {
        mergeState({
          selectedMonthForDimensionalChart: interaction.monthString,
        });
        return;
      }

      case RampPlansTable.INTERACTION_DELETE_BUTTON_CLICKED: {
        deleteRampPlan({
          rampPlanID: interaction.rampPlanID,
        });
        activityTracker.captureAction(actions.CLICK_RAMP_PLAN_DELETE);
        return;
      }

      case RampPlansTable.INTERACTION_DISPLAY_BUTTON_CLICKED: {
        setSearchParamState({
          selectedRampPlanIDToDisplay: interaction.rampPlanID,
        });
        activityTracker.captureAction(actions.SELECT_RAMP_PLAN_TO_DISPLAY);
        return;
      }

      case RampPlansTable.INTERACTION_UPDATE_BUTTON_CLICKED: {
        handleOpenEditForm(interaction.rampPlanID);
        activityTracker.captureAction(actions.CLICK_RAMP_PLAN_UPDATE);
        return;
      }

      case RampPlansTable.INTERACTION_COPY_BUTTON_CLICKED: {
        mergeState({ isCopy: true });
        handleOpenEditForm(interaction.rampPlanID);
        activityTracker.captureAction(actions.CLICK_RAMP_PLAN_COPY);
        return;
      }

      case RampPlansTable.INTERACTION_CSV_DOWNLOAD_CLICKED: {
        setState((currentState) => {
          const sortedCommitments = interaction.rampPlan.commitments.sort(
            (a, b) => {
              if (isBefore(new Date(a.start), new Date(b.start))) {
                return -1;
              } else return 1;
            }
          );

          return {
            ...currentState,
            actualDimensionalSpendQueryListForCSV: [
              ...currentState.actualDimensionalSpendQueryListForCSV,
              {
                id: interaction.rampPlan.id,
                billingAccountIDs: interaction.rampPlan.billingAccountIDs,
                dateRange: [
                  startOfMonth(
                    new Date(getUTCMonthString(sortedCommitments[0].start))
                  ),
                  endOfMonth(
                    new Date(
                      getUTCMonthString(
                        sortedCommitments[sortedCommitments.length - 1].end
                      )
                    )
                  ),
                ],
              },
            ],
          };
        });
        activityTracker.captureAction(actions.CLICK_RAMP_PLAN_DOWNLOAD_CSV);
        return;
      }

      case RampPlansTableSection.INTERACTION_CREATE_BUTTON_CLICKED: {
        handleOpenCreateForm();
        activityTracker.captureAction(actions.CLICK_RAMP_PLAN_OPEN_CREATE_FORM);
        return;
      }
    }
  }

  //
  // Render
  //

  const pricingOptions = [
    {
      label: copyText.pricingOptionCustom,
      value: "custom",
    },
    {
      label: copyText.pricingOptionList,
      value: "list",
    },
  ];

  if (!gatekeeper.canListRampPlans) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const historicalCostDataGroupedByMonth = historicalCostData.reduce(
    (accum, datum) => {
      const month = datum.date.split("-").slice(0, 2).join("-");

      let customNetCostTotal = 0;
      let netCostTotal = 0;

      const dimensionalCost = datum.dimensions.reduce((accum, dimension) => {
        customNetCostTotal += dimension.customNetCost;
        netCostTotal += dimension.netCost;

        return {
          ...accum,
          [dimension.dimension]: {
            customNetCost: dimension.customNetCost,
            netCost: dimension.netCost,
          },
        };
      }, {});

      return {
        ...accum,
        [month]: {
          ...dimensionalCost,
          totals: { customNetCost: customNetCostTotal, netCost: netCostTotal },
        },
      };
    },
    {}
  );

  return (
    <>
      {gatekeeper.canApplyCustomPricing && (
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          marginTop={`-${theme.space_jumbo}`}
          marginBottom={theme.space_md}
        >
          <Text marginRight={theme.space_sm} marginVertical={0}>
            {copyText.pricingHeader}
          </Text>
          <Box width={200}>
            <Select
              options={pricingOptions}
              value={pricingOptions.find((option) => {
                return option.value === searchParamState.pricing;
              })}
              onChange={(option) =>
                option && setSearchParamState({ pricing: option.value })
              }
            />
          </Box>
        </Flex>
      )}
      {searchParamState.showCreateForm ||
      searchParamState.selectedRampPlanIDToEdit
        ? renderConfiguration()
        : renderVisibility()}
    </>
  );

  function renderConfiguration() {
    const isLoadingConfigSection =
      state.configurationBillingAccountIDs !== undefined &&
      state.configurationStartDate !== undefined
        ? isLoadingActualsForConfigurationView
        : false;

    return (
      <RampPlanConfigurationSection
        actualSpend={actualsForConfigurationView.map((actual) => ({
          date: actual.date,
          netCost: actual[selectedPricingDataKey],
        }))}
        billingAccounts={billingAccounts}
        isLoadingBillingAccounts={isLoadingBillingAccounts}
        isLoadingHistoricalCostData={isLoadingHistoricalCostData}
        loadingActuals={isLoadingConfigSection}
        loadingMutation={isCreatingRampPlan || isUpdatingRampPlan}
        isCopy={state.isCopy}
        existingRampPlan={
          (searchParamState.selectedRampPlanIDToEdit &&
            rampPlans?.find(
              (rp) => rp.id === searchParamState.selectedRampPlanIDToEdit
            )) ||
          undefined
        }
        fetchHistoricalCostData={state.fetchHistoricalCostData}
        historicalCostData={historicalCostDataGroupedByMonth}
        selectedMonthForDimensionalChart={
          state.selectedMonthForDimensionalChart
        }
        onInteraction={handleInteraction}
      />
    );
  }

  function renderVisibility() {
    let chartSectionLoading =
      isLoadingBillingAccounts ||
      isLoadingPreviousNetCostByRampPlan ||
      isLoadingRampPlans ||
      !isRampPlansFetched;

    chartSectionLoading = selectedRampPlan
      ? chartSectionLoading || isLoadingActualsForSelected
      : chartSectionLoading;

    const tableSectionLoading = isLoadingRampPlans || !isRampPlansFetched;

    const isLoadingDimensional =
      selectedRampPlan !== undefined && isRampPlansFetched
        ? isLoadingActualDimensionalsForSelected
        : false;

    return (
      <>
        <RampPlanChartSection
          actualSpend={actualsForSelected.map((entry) => ({
            date: entry.date,
            netCost: entry[selectedPricingDataKey],
          }))}
          actualSpendDimensional={actualDimensionalsForSelected.map(
            (entry) => ({
              date: entry.date,
              dimensions: entry.dimensions.map((dimension) => ({
                dimension: dimension.dimension,
                netCost: dimension[selectedPricingDataKey],
              })),
            })
          )}
          loading={chartSectionLoading}
          loadingDimensional={isLoadingDimensional || chartSectionLoading}
          rampPlan={selectedRampPlan}
          previousNetCostByRampPlanID={previousNetCostByRampPlanID}
          selectedMonthForDimensionalChart={
            state.selectedMonthForDimensionalChart
          }
          onInteraction={handleInteraction}
        />
        <RampPlanTableSection
          loading={tableSectionLoading}
          loadingAllActuals={isLoadingPreviousNetCostByRampPlan}
          loadingDelete={isDeletingRampPlan}
          rampPlans={rampPlans || []}
          previousNetCostByRampPlanID={previousNetCostByRampPlanID}
          rampPlanCSVQueryResultList={rampPlanCSVQueryResultList.map(
            (result, i) => ({
              rampPlanID: state.actualDimensionalSpendQueryListForCSV[i]?.id,
              data: result.data?.map((datum) => ({
                date: datum.date,
                dimensions: datum.dimensions.map((dimension) => ({
                  dimension: dimension.dimension,
                  netCost: dimension[selectedPricingDataKey],
                })),
              })),
              isLoading: result.isLoading,
              selectedPricing: searchParamState.pricing,
            })
          )}
          selectedRampPlanID={
            searchParamState.selectedRampPlanIDToDisplay ?? undefined
          }
          onInteraction={handleInteraction}
        />
      </>
    );
  }
}
