import useGetChangePercent from "@/api/core/hooks/useGetChangePercent";
import useGatekeeper from "@/hooks/useGatekeeper";
import IconAzureSQL from "@/ui-lib/icons/IconAzureSQL";
import IconGCPSpanner from "@/ui-lib/icons/IconGCPSpanner";
import {
  CloudProviderType,
  DataSource,
  Operator,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import useGetSpendSummaries from "../../../api/analytics/useGetSpendSummaries";
import paths from "../../../constants/paths";
import IconAwsElastiCache from "../../../ui-lib/icons/IconAWSElastiCache";
import IconAwsMemoryDB from "../../../ui-lib/icons/IconAwsMemoryDB";
import IconAwsOpenSearch from "../../../ui-lib/icons/IconAwsOpenSearch";
import IconAwsRds from "../../../ui-lib/icons/IconAwsRds";
import IconGCPCloudSQL from "../../../ui-lib/icons/IconGCPCloudSQL";
import IconSnowflake from "../../../ui-lib/icons/IconSnowflake";
import useGetAWSDatabaseElastiCacheSpendSummaries from "../aws-database-elasticache/hooks/useGetAWSDatabaseElastiCacheSpendSummaries";
import useGetAWSOpenSearchSpendSummaries from "../aws-open-search/hooks/useGetAWSOpenSearchSpendSummaries";
import copyText from "../copyText";
import useGetCostSpendSummaries from "../hooks/useGetCostSpendSummaries";
import useGetDatabaseSpendSummaries from "../hooks/useGetDatabaseSpendSummaries";
import NotConfiguredSectionBadge from "./NotConfiguredSectionBadge";
import ServiceSectionBadge from "./ServiceSectionBadge";

export default function DatabaseInsightsLandingPage() {
  const gatekeeper = useGatekeeper();

  //
  // Queries
  //

  const gcpCloudSqlSpendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.CLOUD_SQL_COST,
  });

  const {
    data: currentGcpCloudSqlMtdSpend,
    isLoading: isLoadingCurrentGcpCloudSqlMtdSpend,
  } = gcpCloudSqlSpendSummaries[0];

  const {
    data: lastGcpCloudSqlMtdSpend,
    isLoading: isLoadingLastGcpCloudSqlMtdSpend,
  } = gcpCloudSqlSpendSummaries[2];

  const gcpCloudSqlMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastGcpCloudSqlMtdSpend?.totalCost ?? 0,
    value: currentGcpCloudSqlMtdSpend?.totalCost ?? 0,
  });

  const gcpSpannerSpendSummaries = useGetCostSpendSummaries({
    dataSource: DataSource.CLOUD_SPANNER,
  });

  const {
    data: currentGcpSpannerMtdSpend,
    isLoading: isLoadingCurrentGcpSpannerMtdSpend,
  } = gcpSpannerSpendSummaries[0];

  const {
    data: lastGcpSpannerMtdSpend,
    isLoading: isLoadingLastGcpSpannerMtdSpend,
  } = gcpSpannerSpendSummaries[2];

  const gcpSpannerMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastGcpSpannerMtdSpend?.cost ?? 0,
    value: currentGcpSpannerMtdSpend?.cost ?? 0,
  });

  const awsDatabaseSpendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.AWS_DATABASE_VISIBILITY,
  });

  const { data: currentAwsMtdSpend, isLoading: isLoadingCurrentAwsMtdSpend } =
    awsDatabaseSpendSummaries[0];

  const { data: lastAwsMtdSpend, isLoading: isLoadingLastAwsMtdSpend } =
    awsDatabaseSpendSummaries[2];

  const awsMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAwsMtdSpend?.totalCost ?? 0,
    value: currentAwsMtdSpend?.totalCost ?? 0,
  });

  const awsMemoryDatabaseSpendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.AWS_DATABASE_MEMORY_DB,
  });

  const {
    data: currentAwsMemoryDBMtdSpend,
    isLoading: isLoadingCurrentAwsMemoryDBMoMtdSpend,
  } = awsMemoryDatabaseSpendSummaries[0];

  const {
    data: lastAwsMemoryDBMtdSpend,
    isLoading: isLoadingLastAwsMemoryDBMtdSpend,
  } = awsMemoryDatabaseSpendSummaries[2];

  const awsMemoryDBMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAwsMemoryDBMtdSpend?.totalCost ?? 0,
    value: currentAwsMemoryDBMtdSpend?.totalCost ?? 0,
  });

  const awsDatabaseElastiCacheSpendSummaries =
    useGetAWSDatabaseElastiCacheSpendSummaries({});

  const {
    data: currentAWSElastiCacheMtdSpend,
    isLoading: isLoadingCurrentAWSElastiCacheMtdSpend,
  } = awsDatabaseElastiCacheSpendSummaries[0];

  const {
    data: lastAWSElastiCacheMtdSpend,
    isLoading: isLoadingLastAWSElastiCacheMtdSpend,
  } = awsDatabaseElastiCacheSpendSummaries[2];

  const awsElastiCacheMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAWSElastiCacheMtdSpend?.totalCost ?? 0,
    value: currentAWSElastiCacheMtdSpend?.totalCost ?? 0,
  });

  const azureDatabaseSpendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.AZURE_SQL_VISIBILITY,
  });

  const {
    data: currentAzureMtdSpend,
    isLoading: isLoadingCurrentAzureMtdSpend,
  } = azureDatabaseSpendSummaries[0];

  const { data: lastAzureMtdSpend, isLoading: isLoadingLastAzureMtdSpend } =
    azureDatabaseSpendSummaries[2];

  const azureMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAzureMtdSpend?.totalCost ?? 0,
    value: currentAzureMtdSpend?.totalCost ?? 0,
  });

  const openSearchSpendSummary = useGetAWSOpenSearchSpendSummaries();

  const {
    data: currentOpenSearchMTDSpend,
    isLoading: isLoadingCurrentOpenSearchMTDSpend,
  } = openSearchSpendSummary[0];

  const { data: lastOpenSearchSpend, isLoading: isLoadingLastOpenSearchSpend } =
    openSearchSpendSummary[2];

  const awsOpenSearchSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastOpenSearchSpend?.totalCost ?? 0,
    value: currentOpenSearchMTDSpend?.totalCost ?? 0,
  });

  const snowflakeSpendSummary = useGetSpendSummaries({
    dataSource: DataSource.BILLING,
    queryFilters: [
      {
        name: "vendor",
        operator: Operator.EQUALS,
        values: ["Snowflake"],
      },
      {
        name: "skuDescription",
        operator: Operator.CONTAINS,
        values: ["storage", "reader storage"],
      },
    ],
  });

  const {
    data: currentSnowflakeMtdSpend,
    isLoading: isLoadingSnowflakeMTDSpend,
  } = snowflakeSpendSummary[0];

  const {
    data: lastSnowflakeMtdSpend,
    isLoading: isLoadingSnowflakeMonthlySpend,
  } = snowflakeSpendSummary[2];

  const snowflakeMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastSnowflakeMtdSpend?.grossCost ?? 0,
    value: currentSnowflakeMtdSpend?.grossCost ?? 0,
  });

  //
  // Render
  //

  const isLoadingAWSSpend =
    isLoadingCurrentAwsMtdSpend || isLoadingLastAwsMtdSpend;

  const isLoadingAWSMemorySpend =
    isLoadingCurrentAwsMemoryDBMoMtdSpend || isLoadingLastAwsMemoryDBMtdSpend;

  const isLoadingAWSElastiCacheSpend =
    isLoadingCurrentAWSElastiCacheMtdSpend ||
    isLoadingLastAWSElastiCacheMtdSpend;

  const isLoadingAzureSpend =
    isLoadingCurrentAzureMtdSpend || isLoadingLastAzureMtdSpend;

  const isLoadingAWSOpenSearchSpend =
    isLoadingCurrentOpenSearchMTDSpend || isLoadingLastOpenSearchSpend;

  const isLoadingGcpCloudSqlSpend =
    isLoadingCurrentGcpCloudSqlMtdSpend || isLoadingLastGcpCloudSqlMtdSpend;

  const isLoadingGcpSpannerSpend =
    isLoadingCurrentGcpSpannerMtdSpend || isLoadingLastGcpSpannerMtdSpend;

  const isLoadingSnowflakeSpend =
    isLoadingSnowflakeMTDSpend || isLoadingSnowflakeMonthlySpend;

  return (
    <Box>
      {gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">{copyText.awsServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAWSElastiCacheMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAWSElastiCacheSpend}
            mtdSpendComparison={awsElastiCacheMtdSpendComparison}
            path={paths._insightsDatabaseElastiCache}
            serviceIcon={<IconAwsElastiCache size={50} />}
            serviceName={copyText.awsDatabaseElastiCacheTitle}
          />
          <ServiceSectionBadge
            currentMTDSpend={currentAwsMemoryDBMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAWSMemorySpend}
            mtdSpendComparison={awsMemoryDBMtdSpendComparison}
            path={paths._insightsDatabaseMemoryDB}
            serviceIcon={<IconAwsMemoryDB size={50} />}
            serviceName={copyText.awsMemoryDBTitle}
          />
          <ServiceSectionBadge
            currentMTDSpend={currentOpenSearchMTDSpend?.totalCost ?? 0}
            isLoading={isLoadingAWSOpenSearchSpend}
            mtdSpendComparison={awsOpenSearchSpendComparison}
            path={paths._insightsDatabaseOpenSearch}
            serviceIcon={<IconAwsOpenSearch size={50} />}
            serviceName={copyText.openSearchTitle}
          />
          <ServiceSectionBadge
            currentMTDSpend={currentAwsMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAWSSpend}
            mtdSpendComparison={awsMtdSpendComparison}
            path={paths._insightsDatabaseRDS}
            serviceIcon={<IconAwsRds size={50} />}
            serviceName={copyText.rdsTitle}
          />
        </>
      )}
      {gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">{copyText.azureServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAzureMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAzureSpend}
            mtdSpendComparison={azureMtdSpendComparison}
            path={paths._insightsDatabaseAzureSQL}
            serviceIcon={<IconAzureSQL size={50} />}
            serviceName={copyText.azureDatabasesLabel}
          />
        </>
      )}
      {gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">{copyText.gcpServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentGcpCloudSqlMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingGcpCloudSqlSpend}
            mtdSpendComparison={gcpCloudSqlMtdSpendComparison}
            path={paths._insightsDatabaseCloudSQL}
            serviceIcon={<IconGCPCloudSQL size={50} />}
            serviceName={copyText.cloudSQLTitle}
          />
          <ServiceSectionBadge
            currentMTDSpend={currentGcpSpannerMtdSpend?.cost ?? 0}
            isLoading={isLoadingGcpSpannerSpend}
            mtdSpendComparison={gcpSpannerMtdSpendComparison}
            path={paths._insightsDatabaseSpanner}
            serviceIcon={<IconGCPSpanner size={50} />}
            serviceName={copyText.spannerTitle}
          />
        </>
      )}
      {gatekeeper.hasSnowflakeIntegration && (
        <>
          <Text appearance="h3">{copyText.snowflakeSerivesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentSnowflakeMtdSpend?.grossCost ?? 0}
            isLoading={isLoadingSnowflakeSpend}
            mtdSpendComparison={snowflakeMtdSpendComparison}
            path={paths._insightsDatabaseSnowflake}
            serviceIcon={<IconSnowflake size={50} />}
            serviceName={copyText.snowflakeDatabasesLabel}
          />
        </>
      )}

      {!gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">
            {copyText.awsServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsElastiCache size={50} />}
            serviceName={copyText.awsDatabaseElastiCacheTitle}
          />
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsMemoryDB size={50} />}
            serviceName={copyText.awsMemoryDBTitle}
          />
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsOpenSearch size={50} />}
            serviceName={copyText.openSearchTitle}
          />
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsRds size={50} />}
            serviceName={copyText.rdsTitle}
          />
        </>
      )}
      {!gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">
            {copyText.azureServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AZURE}
            serviceIcon={<IconAzureSQL size={50} />}
            serviceName={copyText.azureDatabasesLabel}
          />
        </>
      )}
      {!gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">
            {copyText.gcpServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.GCP}
            serviceIcon={<IconGCPCloudSQL size={50} />}
            serviceName={copyText.cloudSQLTitle}
          />
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.GCP}
            serviceIcon={<IconGCPSpanner size={50} />}
            serviceName={copyText.spannerTitle}
          />
        </>
      )}
      {!gatekeeper.hasSnowflakeIntegration && (
        <>
          <Text appearance="h3">
            {copyText.snowflakeServicesListNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.SNOWFLAKE}
            serviceIcon={<IconSnowflake size={50} />}
            serviceName={copyText.snowflakeDatabasesLabel}
          />
        </>
      )}
    </Box>
  );
}
