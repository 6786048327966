import { QueryClient } from "@tanstack/react-query";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import { TenantEntity } from "@ternary/api-lib/core/types";
import {
  AuthenticatedUserEntity,
  GrantUsersTenantAccessParams,
} from "../api/core/types";

export function updateAuthenticatedUserGrants(
  queryClient: QueryClient,
  params: GrantUsersTenantAccessParams
) {
  const tenants = queryClient.getQueryData<TenantEntity[]>(["tenants"]);

  if (!tenants) return;

  queryClient.setQueryData<AuthenticatedUserEntity>(
    ["authenticatedUser"],
    (authenticatedUser) => {
      if (!authenticatedUser) return;

      const tenant = tenants.find((tenant) => tenant.id === params.tenantID);

      if (!tenant) return;

      const globalGrant = authenticatedUser.grants.find(
        (grant) => grant.tenantDocID === SYSTEM_TENANT_ID
      );

      if (!globalGrant) return;

      return {
        ...authenticatedUser,
        grants: [
          ...authenticatedUser.grants,
          {
            id: `${tenant.fsDocID}:${authenticatedUser.id}`,
            tenantDocID: tenant.fsDocID,
            roles: globalGrant.roles,
          },
        ],
        tenants: [...authenticatedUser.tenants, tenant],
      };
    }
  );
}
