import { useTheme } from "@emotion/react";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatTimestamp } from "@ternary/web-ui-lib/charts/utils";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { formatDistance } from "date-fns";
import React from "react";
import copyText from "../copyText";
import { getIntegrationStatus, getIntegrationStatusColor } from "../utils";
import { IntegrationStatusVariant } from "../constants";
import { IntegrationStatusTag } from "./IntegrationStatusTag";

interface Props {
  latestRefresh: string | null;
  latestUpstream: string | null;
  showOutline?: boolean;
  style?: React.CSSProperties;
}

export default function DataStatusTooltip(props: Props) {
  const theme = useTheme();

  // NOTE: Refresh - by Ternary. Upstream - by cloud provider.
  // "2022-09-17T10:00:00Z"

  function getDataStatusElements(params: {
    latestRefresh: string | null;
    latestUpstream: string | null;
  }) {
    const status = getIntegrationStatus({
      latestRefresh: params.latestRefresh,
      latestUpstream: params.latestUpstream,
    });
    const color = getIntegrationStatusColor(status, theme);

    if (
      status === IntegrationStatusVariant.INCOMPLETE ||
      !params.latestUpstream ||
      !params.latestRefresh
    ) {
      return {
        color,
        status,
        tooltipText: copyText.initialDataRefreshNotCompletedText,
      };
    }

    const now = new Date();
    const absoluteRefresh = new Date(params.latestRefresh);
    const absoluteUpstream = new Date(params.latestUpstream);
    const relativeRefresh = formatDistance(now, absoluteRefresh);
    const upstreamRefresh = formatDistance(now, absoluteUpstream);

    const tooltipText = copyText.dataRefreshStatus
      .replace("%refresh%", relativeRefresh)
      .replace("%upstream%", upstreamRefresh)
      .replace(
        "%upstreamtimestamp%",
        formatTimestamp(params.latestUpstream, "MM/dd/yyyy hh:mm a")
      );

    return { color, status, tooltipText };
  }

  const { color, status, tooltipText } = getDataStatusElements({
    latestRefresh: props.latestRefresh,
    latestUpstream: props.latestUpstream,
  });

  return (
    <Flex alignItems="center" marginLeft={theme.size_tiny} style={props.style}>
      <Tooltip content={tooltipText} width="200px">
        {props.showOutline ? (
          <IntegrationStatusTag variant={status}>
            <Text>1</Text>
            <Icon icon={faCloud} color={color} size="1x" />
          </IntegrationStatusTag>
        ) : (
          <Icon icon={faCloud} color={color} size="1x" />
        )}
      </Tooltip>
    </Flex>
  );
}
