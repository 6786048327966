export const anomalyDetectionActions = {
  CLICK_ANOMALY_DETECTION_ALERT_EVENT_TO_DISPLAY:
    "CLICK_ANOMALY_DETECTION_ALERT_EVENT_TO_DISPLAY",
  CLICK_ANOMALY_DETECTION_CREATE_ALERT_RULE:
    "CLICK_ANOMALY_DETECTION_CREATE_ALERT_RULE",
  CLICK_ANOMALY_DETECTION_CREATE_CASE_ANOMALY_MENU:
    "CLICK_ANOMALY_DETECTION_CREATE_CASE_ANOMALY_MENU",
  CLICK_ANOMALY_DETECTION_CREATE_CASE_DETAIL_MODAL:
    "CLICK_ANOMALY_DETECTION_CREATE_CASE_DETAIL_MODAL",
  CLICK_ANOMALY_DETECTION_INVESTIGATE: "CLICK_ANOMALY_DETECTION_INVESTIGATE",
  CLICK_ANOMALY_DETECTION_OPEN_ALERT_EVENT_FEED:
    "CLICK_ANOMALY_DETECTION_OPEN_ALERT_EVENT_FEED",
  CLICK_ANOMALY_DETECTION_UPDATE_ALERT_RULE:
    "CLICK_ANOMALY_DETECTION_UPDATE_ALERT_RULE",
} as const;
