import { DEFAULT_CHART_GROUPINGS_LIMIT } from "../constants/analytics";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "../constants/enums";
import CubeQuery from "./CubeQuery";
import DataQuery from "./DataQuery";
import { FiscalPeriodMap, createFiscalDates } from "./fiscalDateUtils";
import { AndFilter, LabelMap, Order, QueryFilter, RawData } from "./types";
import { getLabelMappedData } from "./utils";

const OTHER_NOT_SHOWN = "Other (not shown)";
export const DATASET_LIMIT = 50_000;

// TOOD: Bring these back when they are working in DA
type DatalligatorResult<TData> = {
  // has_more: boolean;
  // offset: number;
  response: TData[];
  // total: Record<string, number>;
};

export interface AnalyticsApiClient {
  load(query: CubeQuery): Promise<RawData[]>;
  loadData<TData extends RawData = RawData>(
    tenantID: string,
    query: DataQuery
  ): Promise<DatalligatorResult<TData>>;
}

export type Dependencies = {
  labelMap: LabelMap;
  client: AnalyticsApiClient;
};

export interface Params {
  dateRange: Date[] | [string, string];
  dataSource: DataSource;
  dimensions?: string[];
  durationType?: DurationType;
  isComparisonMode?: boolean;
  isFiscalMode?: boolean;
  fillMissingDates?: boolean;
  fiscalPeriodMap?: FiscalPeriodMap | null;
  granularity?: TimeGranularity;
  limit?: number;
  measures: string[];
  queryFilters?: QueryFilter[];
  order?: Order;
  overflow?: boolean;
}

export default async function getTopNRawData(
  dependencies: Dependencies,
  params: Params
): Promise<RawData[]> {
  params = { ...params };

  // NOTE: to prevent refetches based on dimension order change when available in cache (not safe for measures)
  params.dimensions = [...(params.dimensions ?? [])].sort();

  const nonCreditMeasures = params.measures.filter(
    (measure) => !isCredit(measure)
  );

  const reversedLabelMap = Object.entries(dependencies.labelMap).reduce(
    (accum: { [key: string]: string }, [key, value]) => ({
      ...accum,
      [String(value)]: key,
    }),
    {}
  );

  // Query 0: get all data. If the response hits the 50k limit and we want to truncate, go to query 1.
  let result = await dependencies.client.load(
    new CubeQuery({
      ...params,
      labelMap: dependencies.labelMap,
    })
  );

  const shouldNotExecuteTopN =
    params.overflow === true || result.length < DATASET_LIMIT || !!params.limit;

  if (shouldNotExecuteTopN) {
    if (params.isFiscalMode && params.granularity) {
      result = createFiscalDates(result, params.granularity);
    }
    return getLabelMappedData(result, reversedLabelMap);
  }

  // Query 1: totals across time series with granularity and no dimensions
  const totalsParams = {
    dateRange: params.dateRange,
    dataSource: params.dataSource,
    fiscalPeriodMap: params.fiscalPeriodMap,
    granularity: params.granularity,
    isComparisonMode: params.isComparisonMode,
    isFiscalMode: params.isFiscalMode,
    measures: params.measures,
    queryFilters: params.queryFilters,
  };

  // Query 2: one query for primary measure: top N dimensions across time series without granularity and all dimensions
  const [primaryMeasure] = nonCreditMeasures;
  const topNDimensionsParams: Params = {
    dataSource: params.dataSource,
    dateRange: params.dateRange,
    dimensions: params.dimensions,
    limit: params.limit ?? DEFAULT_CHART_GROUPINGS_LIMIT,
    measures: [primaryMeasure],
    order: { [primaryMeasure]: "desc" },
    queryFilters: params.queryFilters,
  };

  // Load query 1 & 2 in parallel
  let [topNTotalsResult, topNDimensionsResult] = await Promise.all([
    dependencies.client.load(
      new CubeQuery({
        ...totalsParams,
        labelMap: dependencies.labelMap,
      })
    ),

    dependencies.client.load(
      new CubeQuery({
        ...topNDimensionsParams,
        labelMap: dependencies.labelMap,
      })
    ),
  ]);

  topNTotalsResult = getLabelMappedData(topNTotalsResult, reversedLabelMap);

  topNDimensionsResult = getLabelMappedData(
    topNDimensionsResult,
    reversedLabelMap
  );

  if (params.isFiscalMode && params.granularity) {
    topNTotalsResult = createFiscalDates(topNTotalsResult, params.granularity);
  }

  // Query 3: one query for all measures: all data for top N dimensions across time series with granularity and all dimensions
  const topNDataParams: Params = {
    dataSource: params.dataSource,
    dateRange: params.dateRange,
    dimensions: params.dimensions,
    durationType: params.durationType,
    fiscalPeriodMap: params.fiscalPeriodMap,
    granularity: params.granularity,
    isFiscalMode: params.isFiscalMode,
    measures: params.measures,
    queryFilters: params.queryFilters,
  };

  const newOrQueryFilter = topNDimensionsResult.map((row) => {
    return (params.dimensions ? params.dimensions : []).reduce<AndFilter>(
      (accum, currentDimension) => {
        return {
          and: [
            ...(accum.and ? accum.and : []),
            ...(row[currentDimension] !== null
              ? [
                  {
                    name: currentDimension,
                    operator: Operator.EQUALS,
                    values: [String(row[currentDimension])],
                  },
                ]
              : [
                  {
                    name: currentDimension,
                    operator: Operator.NOT_SET,
                  },
                ]),
          ],
        };
      },
      { and: [] }
    );
  });

  if (params.dimensions) {
    topNDataParams.queryFilters = [
      ...(topNDataParams.queryFilters ? topNDataParams.queryFilters : []),
      { or: newOrQueryFilter },
    ];
  }

  let topNDataResults = await dependencies.client.load(
    new CubeQuery({
      ...topNDataParams,
      labelMap: dependencies.labelMap,
    })
  );

  if (params.isFiscalMode && params.granularity) {
    topNDataResults = createFiscalDates(topNDataResults, params.granularity);
  }

  topNDataResults = getLabelMappedData(topNDataResults, reversedLabelMap);

  const otherData = params.limit
    ? []
    : topNTotalsResult.map((totalDatum): RawData => {
        const otherDatum = { ...totalDatum };

        params.dimensions?.forEach((dimension) => {
          if (!otherDatum[dimension]) {
            otherDatum[dimension] = OTHER_NOT_SHOWN;
          }
        });

        topNDataResults.forEach((topNDatum) => {
          if (topNDatum.timestamp === otherDatum.timestamp) {
            params.measures.forEach((measure) => {
              if (topNDatum[measure] !== undefined) {
                const otherValue = Number(otherDatum[measure] ?? 0);
                const topNValue = Number(topNDatum[measure] ?? 0);

                const value = otherValue - topNValue;

                otherDatum[measure] = value < 0 ? 0 : value;
              } else {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete otherDatum[measure];
              }
            });
          }
          return otherDatum;
        });

        return otherDatum;
      });

  return [...topNDataResults, ...otherData];
}

const creditMeasures = [
  "absoluteCreditsCommittedUsageDiscount",
  "absoluteCreditsCommittedUsageDiscountDollarBase",
  "absoluteCreditsDiscount",
  "absoluteCreditsFreeTier",
  "absoluteCreditsPromotion",
  "absoluteCreditsSubscriptionBenefit",
  "absoluteCreditsSustainedUsageDiscount",
];

function isCredit(measure: string) {
  return creditMeasures.includes(measure);
}
