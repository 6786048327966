import Modal from "@/ui-lib/components/Modal";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

type Props = {
  onInteraction: (interaction: BudgetTypeModal.Interaction) => void;
  onClose: () => void;
};

export function BudgetTypeModal(props: Props): JSX.Element {
  return (
    <Modal isOpen={true} showCloseButton width={300} onClose={props.onClose}>
      <Modal.Header>
        <Text appearance="h4">{copyText.addBudgetSelectType}</Text>
      </Modal.Header>

      <Modal.Body>
        <Flex justifyContent="space-between">
          <Button
            primary
            onClick={() =>
              props.onInteraction({
                type: BudgetTypeModal.INTERACTION_SELECT_DAILY_TRENDS,
              })
            }
          >
            {copyText.addBudgetDaily}
          </Button>
          <Button
            primary
            onClick={() =>
              props.onInteraction({
                type: BudgetTypeModal.INTERACTION_SELECT_CURRENT_MONTH,
              })
            }
          >
            {copyText.addBudgetMonthly}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BudgetTypeModal {
  export const INTERACTION_SELECT_CURRENT_MONTH = `BudgetTypeModal.INTERACTION_SELECT_CURRENT_MONTH`;
  export const INTERACTION_SELECT_DAILY_TRENDS = `BudgetTypeModal.INTERACTION_SELECT_DAILY_TRENDS`;

  interface InteractionSelectCurrentMonth {
    type: typeof BudgetTypeModal.INTERACTION_SELECT_CURRENT_MONTH;
  }

  interface InteractionSelectDailyTrends {
    type: typeof BudgetTypeModal.INTERACTION_SELECT_DAILY_TRENDS;
  }

  export type Interaction =
    | InteractionSelectCurrentMonth
    | InteractionSelectDailyTrends;
}

export default BudgetTypeModal;
