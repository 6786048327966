import { adminActions } from "./feature/admin";
import { anomalyDetectionActions } from "./feature/anomalyDetection";
import { caseManagemetActions } from "./feature/caseManagement";
import { commitmentDiscountsActions } from "./feature/commitmentDiscounts";
import { costCompareActions } from "./feature/costCompare";
import { customLabelsActions } from "./feature/customLabels";
import { dateRangeActions } from "./feature/dateRange";
import { forecastingActions } from "./feature/forecasting";
import { insightsActions } from "./feature/insights";
import { mspActions } from "./feature/msp";
import { navigationActions } from "./feature/navigation";
import { rampPlansActions } from "./feature/rampPlans";
import { reallocationsActions } from "./feature/reallocations";
import { scopedViewsActions } from "./feature/scopedViews";
import { treActions } from "./feature/tre";

export interface EventReporterConfig {
  devMode: boolean;
  enableMixpanel: boolean;
  enableSentry: boolean;
}

export interface EventReporter {
  reportError(error: unknown): void;
  reportEvent(name: string, properties: object): void;
  reportMessage(message: string, context?: object): void;
}

// prettier-ignore
export const actions = {
  ...adminActions,
  ...anomalyDetectionActions,
  ...caseManagemetActions,
  ...commitmentDiscountsActions, 
  ...costCompareActions,
  ...customLabelsActions,
  ...dateRangeActions,
  ...forecastingActions,
  ...insightsActions, 
  ...mspActions,
  ...navigationActions,
  ...rampPlansActions,
  ...reallocationsActions,
  ...scopedViewsActions,
  ...treActions
} as const;

export type Action = (typeof actions)[keyof typeof actions];

export interface ActivityTracker {
  captureAction(action: Action, properties?: Record<string, unknown>): void;
}
