export const customLabelsActions = {
  CLICK_CUSTOM_LABEL_CREATE: "CLICK_CUSTOM_LABEL_CREATE",
  CLICK_CUSTOM_LABEL_CREATE_GROUPING_RULE:
    "CLICK_CUSTOM_LABEL_CREATE_GROUPING_RULE",
  CLICK_CUSTOM_LABEL_DELETE: "CLICK_CUSTOM_LABEL_DELETE",
  CLICK_CUSTOM_LABEL_DELETE_GROUPING_RULE:
    "CLICK_CUSTOM_LABEL_DELETE_GROUPING_RULE",
  CLICK_CUSTOM_LABEL_UPDATE: "CLICK_CUSTOM_LABEL_UPDATE",
  CLICK_CUSTOM_LABEL_UPDATE_GROUPING_RULE:
    "CLICK_CUSTOM_LABEL_UPDATE_GROUPING_RULE",
} as const;
