import useGetPaginatedRawData from "@/api/analytics/hooks/useGetPaginatedRawData";
import { fiscalGranularities } from "@ternary/api-lib/analytics/fiscalDateUtils";
import { RawData } from "@ternary/api-lib/analytics/types";
import {
  getInvoiceMonthFilters,
  getMeasureUnit,
} from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import { getFormatForGranularity } from "@ternary/api-lib/ui-lib/utils/dates";
import { formatPercentage } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import {
  getBillingMeasures,
  getComparisonData,
  getComparisonDateRangeFromReport,
  getCumulativeData,
  getDataSourceFilters,
  getDateRangeFromReport,
  getIsInvoiceMonthMode,
  getMappedAndFilteredData,
  getNonUtilizationMeasures,
  getShouldApplyGranularity,
} from "@ternary/api-lib/utils/ReportUtils";
import { Measure } from "@ternary/web-ui-lib/charts/types";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  PERCENT_DIFFERENCE_KEY,
  PREVIOUS_TIMESTAMP_KEY,
  RAW_DIFFERENCE_KEY,
  formatTimestamp,
  getTimeSeriesChartDataFromRawData,
  parseRowStrings,
} from "@ternary/web-ui-lib/charts/utils";
import { sortRawData } from "@ternary/web-ui-lib/utils/sort";
import { add, sub } from "date-fns";
import { useEffect, useMemo } from "react";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import { useFilterStore } from "../../../context/FilterStoreProvider";
import { useGlobalDateStore } from "../../../context/GlobalDateProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { AlertType, postAlert } from "../../../utils/alerts";
import { DateRange } from "../../../utils/dates";
import copyText from "../copyText";
import { CSVDataResult, Order, ReportDataConfig } from "../types";
import {
  getFlatData,
  getFlatTransposeCSVData,
  getSortedTimestampsForTimeSeries,
  limitTimeSeriesCSVRows,
  removeInvalidAccessorCharacters,
} from "../utils";

const CSV_EXPORT_ROW_LIMIT = 600_000;

// NOTE: Using this for TS
const initialReport: ReportDataConfig = {
  id: "",
  compareEndDate: null,
  compareStartDate: null,
  compareDurationType: null,
  chartType: ChartType.STACKED_AREA,
  dataSource: DataSource.BILLING,
  dimensions: [],
  durationType: DurationType.LAST_THIRTY_DAYS,
  endDate: null,
  excludedCreditTypes: [],
  excludeNegativeNumbers: false,
  excludeOther: false,
  filters: [],
  formula: null,
  formulaAlias: null,
  hiddenMeasures: [],
  invoiceMonthEnd: null,
  invoiceMonthStart: null,
  isCumulative: false,
  isFormulaHidden: false,
  isMetricHidden: false,
  limit: null,
  measures: [],
  metric: null,
  metricAggregate: null,
  metricFilters: [],
  nLookback: null,
  reverse: false,
  sortRule: null,
  startDate: null,
  timeGranularity: null,
  xAxisKey: null,
};

interface TopRowObject {
  measure: string;
  dimensions: {
    key: string;
    value: string;
  }[];
}

type UseGetReportCSVConfig = {
  isTranspose?: boolean;
  report: ReportDataConfig | null;
  searchText?: string;
};

type UseGetReportCSVResult = {
  data: CSVDataResult;
  isLoading: boolean;
};

export default function useGetReportCSV(
  config: UseGetReportCSVConfig
): UseGetReportCSVResult {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const filterStore = useFilterStore();
  const globalDateStore = useGlobalDateStore();

  const fiscalPeriodMap = authenticatedUser.tenant.fiscalCalendar?.periods;
  const isFiscalMode = authenticatedUser.settings.fiscalMode;
  const isTranspose = config.isTranspose ?? false;

  const shouldFetchCSVData = !!config.report;

  let report: ReportDataConfig = config.report ?? initialReport;

  report = useReferenceIfEqual(report);

  const globalDateRange = globalDateStore.dateRange;

  const dateRange = globalDateRange
    ? globalDateRange
    : getDateRangeFromReport(report);

  const globalFilters = getDataSourceFilters(
    filterStore.queryFilters,
    report.dataSource
  );

  const qualifiedFilters = report.filters.filter(
    (filter) => !filter.values || (filter.values && filter.values.length > 0)
  );

  let queryFilters = qualifiedFilters;

  const excludeNegativesQueryFilter = report.excludeNegativeNumbers
    ? report.measures.map((measure) => {
        return { name: measure, operator: Operator.GTE, values: ["0"] };
      })
    : [];

  queryFilters = [...queryFilters, ...excludeNegativesQueryFilter];

  const isInvoiceMonthMode = getIsInvoiceMonthMode(report);

  const shouldApplyGranularity = getShouldApplyGranularity(report);

  const dimensions =
    isInvoiceMonthMode && report.chartType !== ChartType.TABLE
      ? [...report.dimensions, "invoiceMonth"]
      : report.dimensions;

  const defaultOrder: Order = {
    [report.measures[0]]: "desc",
  };

  // only order by sort rule when columns are valid dimensions / measures
  // otherwise, use default order
  const order: Order =
    report.sortRule && report.measures.includes(report.sortRule.id)
      ? { [report.sortRule.id]: report.sortRule.desc ? "desc" : "asc" }
      : defaultOrder;

  const searchText = config.searchText ?? "";

  const debouncedSearchFilters = useMemo(() => {
    return searchText.length > 0
      ? report.dimensions.map((dimension) => {
          return {
            name: dimension,
            operator: Operator.CONTAINS,
            values: [searchText],
          };
        })
      : [];
  }, [searchText]);

  const xAxisKey = isInvoiceMonthMode
    ? "invoiceMonth"
    : report.xAxisKey
      ? report.xAxisKey
      : shouldApplyGranularity
        ? DEFAULT_X_AXIS_KEY
        : undefined;

  const shouldApplyInvoiceMonthFilters =
    report.durationType === DurationType.INVOICE &&
    report.dataSource === DataSource.BILLING;

  let csvDateRange = report ? getDateRangeFromReport(report) : null;

  let csvCompareDateRange = report
    ? getComparisonDateRangeFromReport(report)
    : null;

  const invoiceMonthFilters = shouldApplyInvoiceMonthFilters
    ? getInvoiceMonthFilters(dateRange)
    : [];

  const invoiceMonthCompareFilters =
    shouldApplyInvoiceMonthFilters &&
    report.compareDurationType &&
    csvCompareDateRange
      ? getInvoiceMonthFilters(csvCompareDateRange)
      : [];

  if (shouldApplyInvoiceMonthFilters) {
    // Note: "invoiceMonth" filters are used to determine what data to include in the month.
    // dateRange padding is added to ensure nothing is left out

    csvDateRange = csvDateRange && [
      sub(csvDateRange[0], { days: 10 }),
      add(csvDateRange[1], { days: 10 }),
    ];

    csvCompareDateRange = csvCompareDateRange && [
      sub(csvCompareDateRange[0], { days: 10 }),
      add(csvCompareDateRange[1], { days: 10 }),
    ];
  }

  const { data: _csvData, isFetching: isLoadingCSVData } =
    useGetPaginatedRawData(
      {
        dataSource: report?.dataSource ?? DataSource.BILLING,
        dateRange: csvDateRange ?? [],
        dimensions: dimensions,
        durationType: report?.durationType ?? DurationType.TODAY,
        fiscalPeriodMap,
        isFiscalMode: isFiscalMode && !isInvoiceMonthMode,
        granularity: shouldApplyGranularity
          ? (report?.timeGranularity ?? undefined)
          : undefined,
        measures:
          report?.dataSource === DataSource.BILLING
            ? getBillingMeasures(report.measures)
            : report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY
              ? getNonUtilizationMeasures(report.measures)
              : (report?.measures ?? []),
        order,
        queryFilters: [
          ...globalFilters,
          ...queryFilters,
          ...invoiceMonthFilters,
          ...(debouncedSearchFilters.length > 0
            ? [{ or: debouncedSearchFilters }]
            : []),
        ],
        queryKey: ["report", report?.id ?? "NEW"],
      },
      {
        enabled: shouldFetchCSVData,
        meta: { errorMessage: copyText.errorLoadingCSVMessage },
      }
    );

  useEffect(() => {
    if (_csvData && _csvData.length >= CSV_EXPORT_ROW_LIMIT) {
      activityTracker.captureAction(actions.ALERT_TRE_CSV_EXPORT_LIMIT_REACHED);
      postAlert({
        message: copyText.largeCSVMessage,
        type: AlertType.WARNING,
      });
    }
  }, [_csvData]);

  const { data: csvCompareData, isFetching: isLoadingCSVCompareData } =
    useGetPaginatedRawData(
      {
        dataSource: report?.dataSource ?? DataSource.BILLING,
        dateRange: csvCompareDateRange ?? [],
        dimensions: dimensions,
        durationType: report?.durationType ?? DurationType.TODAY,
        fiscalPeriodMap,
        isComparisonMode: true,
        isFiscalMode: isFiscalMode && !isInvoiceMonthMode,
        granularity: shouldApplyGranularity
          ? (report?.timeGranularity ?? undefined)
          : undefined,
        measures:
          report?.dataSource === DataSource.BILLING
            ? getBillingMeasures(report.measures)
            : report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY
              ? getNonUtilizationMeasures(report.measures)
              : (report?.measures ?? []),
        order,
        queryFilters: [
          ...globalFilters,
          ...queryFilters,
          ...invoiceMonthCompareFilters,
          { or: debouncedSearchFilters },
        ],
        queryKey: [
          "report",
          report?.compareDurationType ?? "",
          report?.id ?? "NEW",
        ],
      },
      {
        enabled: shouldFetchCSVData && Boolean(report?.compareDurationType),
        meta: { errorMessage: copyText.errorLoadingCSVMessage },
      }
    );

  useEffect(() => {
    if (csvCompareData && csvCompareData.length >= CSV_EXPORT_ROW_LIMIT) {
      activityTracker.captureAction(actions.ALERT_TRE_CSV_EXPORT_LIMIT_REACHED);
      postAlert({
        message: copyText.largeCSVMessage,
        type: AlertType.WARNING,
      });
    }
  }, [csvCompareData]);

  const csvResult = useMemo(() => {
    const sortedCSVData = sortRawData({
      data: [...(_csvData ?? [])],
      groupingKeys: report?.dimensions,
      order: {
        xAxis: shouldApplyGranularity ? "asc" : "desc",
        yAxis: "desc",
      },
      xAxisKey,
      yAxisKeys: report?.measures ?? [],
    });

    const sortedCSVCompareData = sortRawData({
      data: [...(csvCompareData ?? [])],
      groupingKeys: report?.dimensions,
      order: {
        xAxis: shouldApplyGranularity ? "asc" : "desc",
        yAxis: "desc",
      },
      xAxisKey,
      yAxisKeys: report?.measures ?? [],
    });

    const csvData = report.compareDurationType
      ? report && csvCompareData && _csvData
        ? getComparisonData({
            compareData: sortedCSVCompareData,
            compareDurationType: report.compareDurationType,
            data: sortedCSVData,
            dimensions: report.dimensions,
            measures: report.measures,
          })
        : undefined
      : report.isCumulative
        ? getCumulativeData({
            data: sortedCSVData,
            dimensions: report.dimensions,
            measures: report.measures,
          })
        : sortedCSVData;

    return processCSVData(csvData, {
      isFiscalMode: isFiscalMode,
      report: report,
      isTranspose: isTranspose,
    });
  }, [isFiscalMode, report, _csvData, csvCompareData]);

  return {
    data: csvResult,
    isLoading: isLoadingCSVData || isLoadingCSVCompareData,
  };
}

//
// PROCESS CSV DATA
//

type ProcessCSVDataConfig = {
  report: ReportDataConfig | null;
  isFiscalMode: boolean;
  isTranspose: boolean;
};

function processCSVData(
  csvData: RawData[] | undefined,
  { report, isTranspose, ...config }: ProcessCSVDataConfig
): CSVDataResult {
  csvData ||= [];

  if (!report) return { rows: [], headers: [] };

  csvData = csvData.reduce((accum: RawData[], datum) => {
    const temp = [datum];

    if (
      report &&
      report.compareDurationType &&
      report.xAxisKey === DEFAULT_X_AXIS_KEY &&
      report.timeGranularity
    ) {
      report.measures.forEach((measure) => {
        temp.push({
          ...datum,
          [measure]: datum[`${measure}${COMPARISON_KEY}`],
          timestamp: `${datum[PREVIOUS_TIMESTAMP_KEY]} (${COMPARISON_KEY})`,
        });
      });
    }

    if (
      report &&
      report.compareDurationType &&
      !report.timeGranularity &&
      report.measures.length === 1
    ) {
      report.measures.forEach((measure) => {
        const currentValue = datum[measure];
        const previousValue = datum[`${measure}${COMPARISON_KEY}`];

        if (
          typeof currentValue === "number" &&
          typeof previousValue === "number"
        ) {
          datum[RAW_DIFFERENCE_KEY] = currentValue - previousValue;
          datum[PERCENT_DIFFERENCE_KEY] = formatPercentage(
            (currentValue - previousValue) / currentValue
          );
        }
      });
    }

    return [...accum, ...temp];
  }, []);

  const isInvoiceMonthMode =
    report.durationType === DurationType.INVOICE &&
    report.timeGranularity === TimeGranularity.MONTH;

  const hasFiscalDates =
    config.isFiscalMode &&
    report &&
    !!report.timeGranularity &&
    fiscalGranularities.includes(report.timeGranularity);

  const dateStringsForCSVTimeSeries = report.timeGranularity
    ? getSortedTimestampsForTimeSeries(csvData, {
        isFiscal: hasFiscalDates,
        isInvoiceMonthMode: isInvoiceMonthMode,
      })
    : [];

  const dimensions = report.dimensions.map((dimension) => ({
    name: dimension,
    isDate: false,
  }));

  const measures = [
    ...report.measures.reduce((accum: Measure[], measure) => {
      const temp: Measure[] = [];

      if (
        !report.hiddenMeasures.some(
          (hiddenMeasure) => hiddenMeasure === measure
        )
      ) {
        temp.push({
          name: measure,
          unit: getMeasureUnit(measure, report.dataSource),
        });
      }

      if (
        report.compareDurationType &&
        (!report.timeGranularity || report.xAxisKey !== DEFAULT_X_AXIS_KEY)
      ) {
        temp.push({
          name: `${measure}${COMPARISON_KEY}`,
          unit: getMeasureUnit(measure, report.dataSource),
        });

        if (report.measures.length === 1) {
          temp.push(
            {
              name: RAW_DIFFERENCE_KEY,
              unit: getMeasureUnit(measure, report.dataSource),
            },
            {
              name: PERCENT_DIFFERENCE_KEY,
              unit: UnitType.STANDARD,
            }
          );
        }
      }

      return [...accum, ...temp];
    }, []),
  ];

  let topRowObjects: TopRowObject[] = [];

  if (report.limit && report.measures.length > 0) {
    const [_ignore, allRowsReverseSorted] = getTimeSeriesChartDataFromRawData({
      data: csvData,
      dimensions,
      excludedKeys: [],
      isFiscalMode: config.isFiscalMode,
      maximumGroupings: Infinity,
      measures,
      xAxisKey: DEFAULT_X_AXIS_KEY,
    });

    const topRows = allRowsReverseSorted.slice(-report.limit);

    topRowObjects = topRows.map((row) =>
      parseRowStrings(row, dimensions, measures)
    );
  }

  let filteredCSVData = getMappedAndFilteredData(
    csvData,
    report.excludedCreditTypes
  );

  if (report.limit && report.dimensions.length > 0) {
    filteredCSVData = filteredCSVData.filter((datum) => {
      const found = topRowObjects.find((topRow) => {
        if (datum[topRow.measure] !== undefined) {
          const doesMatchEveryDimension = topRow.dimensions.every(
            (dimension) => {
              // Stringifying value of dimension to make null match "null"
              if (String(datum[dimension.key]) === dimension.value) {
                return true;
              }
            }
          );

          if (doesMatchEveryDimension) {
            return true;
          }
        }
      });

      return found !== undefined;
    });
  }

  const shouldApplyGranularity =
    report.chartType !== ChartType.PIE &&
    report.chartType !== ChartType.TABLE &&
    (report.xAxisKey === DEFAULT_X_AXIS_KEY || !report.xAxisKey);

  // NOTE: In time series data, each measure gets it's own row, even with shared dimensions
  let flatCSVData = getFlatData({
    dimensions: dimensions,
    filteredData: filteredCSVData,
    hasFiscalDates: hasFiscalDates,
    isComparisonMode: !!report.compareDurationType,
    isCumulativeMode: report.isCumulative,
    isInvoiceMonthMode: isInvoiceMonthMode,
    measures: measures,
    timeSeriesGranularity: shouldApplyGranularity
      ? (report.timeGranularity ?? undefined)
      : undefined,
  });

  const flatTransposeCSVData = getFlatTransposeCSVData({
    dimensions: dimensions,
    filteredData: filteredCSVData,
    hasFiscalDates: hasFiscalDates,
    isComparisonMode: !!report.compareDurationType,
    isInvoiceMonthMode: isInvoiceMonthMode,
    measures: measures,
    timeSeriesGranularity: shouldApplyGranularity
      ? (report.timeGranularity ?? undefined)
      : undefined,
  });

  if (shouldApplyGranularity && report.timeGranularity && report.limit) {
    flatCSVData = limitTimeSeriesCSVRows({
      dimensions: dimensions,
      limit: report.limit,
      measures: measures,
      rows: flatCSVData,
    });
  }

  const dimensionHeaders = dimensions.map((dimension) => ({
    label: dimension.name,
    key: removeInvalidAccessorCharacters(dimension.name),
  }));

  const internalMeasureHeader = {
    label: copyText.dataAttributeMeasure,
    key: "selected_measure",
  };

  const internalTransposeDateHeader = {
    label: copyText.dataAttributeDate,
    key: "selected_date",
  };

  const timestampHeaders = dateStringsForCSVTimeSeries.map((timestamp, index) =>
    getTimestampHeaders({
      hasFiscalDates,
      isComparisonMode: !!report.compareDurationType,
      isInvoiceMonthMode,
      timeGranularity: report.timeGranularity ?? TimeGranularity.DAY,
      timestamp,
      index,
    })
  );

  const measureHeaders = measures.map((measure) => {
    const key = removeInvalidAccessorCharacters(measure.name);
    const compareDateRange = getComparisonDateRangeFromReport(report);
    const dateRange = getDateRangeFromReport(report);

    const header =
      !!report.compareDurationType && !report.timeGranularity
        ? getNonTimeSeriesComparisonHeaderLabel(
            compareDateRange,
            dateRange,
            measure.name
          )
        : measure.name;

    return {
      label: header,
      key,
    };
  });

  const nonDimensionHeaders =
    shouldApplyGranularity && report.timeGranularity
      ? [internalMeasureHeader, ...timestampHeaders]
      : measureHeaders;

  let nonDimensionTransposedHeaders = measureHeaders;

  if (dimensionHeaders && shouldApplyGranularity && report.timeGranularity) {
    nonDimensionTransposedHeaders = [
      internalTransposeDateHeader,
      ...dimensionHeaders,
      ...measureHeaders,
    ];
  } else if (
    !dimensionHeaders &&
    shouldApplyGranularity &&
    report.timeGranularity
  ) {
    nonDimensionTransposedHeaders = [
      internalTransposeDateHeader,
      ...measureHeaders,
    ];
  }

  const csvHeaders: { label: string; key: string }[] = [
    ...dimensionHeaders,
    ...nonDimensionHeaders,
    {
      label: copyText.csvTotalsHeader,
      key: "totals",
    },
  ];

  const csvTransposedHeaders: { label: string; key: string }[] = [
    ...nonDimensionTransposedHeaders,
    {
      label: copyText.csvTotalsHeader,
      key: "totals",
    },
  ];

  return {
    rows: isTranspose ? flatTransposeCSVData : flatCSVData,
    headers: isTranspose ? csvTransposedHeaders : csvHeaders,
  };
}

function getNonTimeSeriesComparisonHeaderLabel(
  compareDateRange: DateRange,
  dateRange: DateRange,
  measure: string
): string {
  const timestampFormat = "MM/dd/yyyy";
  if (
    measure.includes(PERCENT_DIFFERENCE_KEY) ||
    measure.includes(RAW_DIFFERENCE_KEY)
  ) {
    return measure;
  }

  if (measure.includes(COMPARISON_KEY)) {
    return `${measure}  (${formatTimestamp(
      compareDateRange[0].toISOString(),
      timestampFormat
    )} - ${formatTimestamp(
      compareDateRange[1].toISOString(),
      timestampFormat
    )})`;
  }

  return `${measure}  (${formatTimestamp(
    dateRange[0].toISOString(),
    timestampFormat
  )} - ${formatTimestamp(dateRange[1].toISOString(), timestampFormat)})`;
}

function getTimestampHeaders(params: {
  hasFiscalDates: boolean;
  isComparisonMode: boolean;
  isInvoiceMonthMode: boolean;
  timeGranularity: TimeGranularity;
  timestamp: string;
  index: number;
}): { key: string; label: string } {
  const exportTimestampFormat = getExportFormatForGranularity(
    params.timeGranularity
  );
  const timestampFormat = getFormatForGranularity(params.timeGranularity);

  const timeGranularityLabel = params.timeGranularity
    ? params.timeGranularity.charAt(0).toUpperCase() +
      params.timeGranularity.slice(1).toLowerCase()
    : TimeGranularity.DAY;

  const formattedPreviousKey = `${formatTimestamp(
    params.timestamp.replace(` (${COMPARISON_KEY})`, ""),
    exportTimestampFormat
  )} (${COMPARISON_KEY})`;

  const formattedPreviousTimestamp = `${formatTimestamp(
    params.timestamp.replace(` (${COMPARISON_KEY})`, ""),
    exportTimestampFormat
  )} (${COMPARISON_KEY} - ${timeGranularityLabel} ${
    (params.index % 2 ? params.index - 1 : params.index + 2) / 2 + 1
  })`;

  const formattedTimestamp = formatTimestamp(
    params.timestamp,
    exportTimestampFormat
  );

  const key =
    params.hasFiscalDates || params.isInvoiceMonthMode
      ? params.timestamp
      : params.timestamp.includes(COMPARISON_KEY)
        ? formattedPreviousKey
        : formatTimestamp(params.timestamp, timestampFormat);

  const header = params.timestamp.includes(COMPARISON_KEY)
    ? formattedPreviousTimestamp
    : params.isInvoiceMonthMode
      ? params.timestamp
      : params.isComparisonMode
        ? `${formattedTimestamp} (Current - ${timeGranularityLabel} ${
            (params.index % 2 ? params.index - 1 : params.index + 2) / 2
          })`
        : formattedTimestamp;

  return { key, label: header };
}

// Only necessary for CSV export header dates
export function getExportFormatForGranularity(
  timeGranularity?: TimeGranularity | null
): string {
  switch (timeGranularity) {
    case TimeGranularity.YEAR:
      return "yyyy";
    case TimeGranularity.MONTH:
      return "MM/yyyy";
    case TimeGranularity.WEEK:
    case TimeGranularity.DAY:
      return "MM/dd/yyyy";
    case TimeGranularity.HOUR:
    case TimeGranularity.MINUTE:
      return "MM/dd/yyyy hh:mm a";
    case TimeGranularity.SECOND:
      return "MM/dd/yyyy hh:mm::ss a";
    default:
      return "yyyy/MM/dd";
  }
}
