import { useTheme } from "@emotion/react";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { DurationType } from "@ternary/api-lib/constants/enums";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import { AlertRuleEntity, GrantEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { convertSnakeCaseToTitleCase } from "@ternary/api-lib/utils/CaseUtils";
import { format } from "date-fns";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { ReportConfig } from "../../../types";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import IconExclamationDiamond from "../../../ui-lib/icons/IconExclamationDiamond";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGetRolesByTenantID from "../../admin/hooks/useGetRolesByTenantID";
import { useGrantUsersTenantAccess } from "../../admin/hooks/useGrantUsersTenantAccess";
import {
  legacyBigQueryAlertRule,
  legacyBillingAlertRule,
} from "../../alert-tracking/defaultAlertRules";
import useGetAlertRuleByID from "../../alert-tracking/hooks/useGetAlertRuleByID";
import {
  CostAlertDimension,
  CostAlertLegacySourceRule,
} from "../../alert-tracking/types";
import {
  alertQueryFilter,
  getAnomalyDateRange,
} from "../../alert-tracking/utils";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspChildResources from "../hooks/useGetMspChildResources";
import useGetMspCostAlertData from "../hooks/useGetMspCostAlertData";
import useGetMspSpendData from "../hooks/useGetMspSpendData";
import MspCostAlertTable from "./MspCostAlertTable";
import { parseArrayParam } from "./MspDashboardPage";

const csvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "createdAt", label: copyText.tableHeaderAlertedAt },
  { key: "delta", label: copyText.tableHeaderDelta },
];

type Interaction = MspCostAlertTable.Interaction;

type State = {
  selectedAlert: {
    id: string;
    dimensions: CostAlertDimension[];
    durationType: DurationType;
    eventTime: string;
  } | null;
  selectedTenantID: string | null;
  showModal: boolean;
};

const initialState: State = {
  selectedAlert: null,
  selectedTenantID: null,
  showModal: false,
};

interface Props {
  parentTenantID: string;
}

export default function MspCostAlertViewContainer(props: Props) {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState] = useQueryParams({
    t_ids: withDefault(ArrayParam, []),
  });

  const tenantDocIDs = parseArrayParam(searchParamState.t_ids);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  const { data: mspResources, isLoading: isLoadingMspResources } =
    useGetMspChildResources(props.parentTenantID);

  const { data: alertRule, isLoading: isLoadingAlertRule } =
    useGetAlertRuleByID(state.selectedAlert?.id ?? "", {
      enabled:
        !!state.selectedAlert?.id &&
        state.selectedAlert.id !==
          CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML &&
        state.selectedAlert.id !==
          CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML,
      includeEvents: false,
    });

  const { data: labelMaps } = useGetLabelMapsByTenantID(
    state.selectedTenantID ?? "",
    { enabled: !!state.selectedTenantID }
  );

  const { data: _mspSpendData = [], isLoading: isLoadingSpendData } =
    useGetMspSpendData({
      dateRange: getDateRangeFromDurationType(DurationType.LAST_NINETY_DAYS),
      dimensions: [],
      parentTenantID: props.parentTenantID ?? "",
      preAggFilters: [],
    });

  const { data: _mspCostAlertData = [], isLoading: isLoadingCostAlertData } =
    useGetMspCostAlertData({
      dateRange: getDateRangeFromDurationType(DurationType.LAST_NINETY_DAYS),
      parentTenantID: props.parentTenantID ?? "",
      preAggFilters: [],
    });

  const { data: roles = [] } = useGetRolesByTenantID(
    props.parentTenantID as string
  );

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // Computed Values
  //

  const labelMap = labelMaps?.BILLING ?? {};

  const reversedLabelMap = Object.entries(labelMap).reduce(
    (accum: { [key: string]: string }, [key, value]) => ({
      ...accum,
      [String(value)]: key,
    }),
    {}
  );

  const costAlerts = mspResources?.alerts.map((alert) => {
    let dimensions = alert.dimensions;

    dimensions = dimensions.map((dimension) => {
      return {
        key: reversedLabelMap[dimension.key] ?? dimension.key,
        value: dimension.value,
      };
    });
    return {
      ...alert,
      dimensions,
      tenantName: tenantsKeyedByDocID[alert.tenantDocID]?.name ?? "null",
    };
  });

  const filteredcostAlerts =
    tenantDocIDs.length > 0
      ? costAlerts?.filter((costAlert) =>
          tenantDocIDs.includes(costAlert.tenantDocID)
        )
      : costAlerts;

  let mspSpendData = _mspSpendData;

  if (tenantDocIDs.length > 0) {
    mspSpendData = _mspSpendData.filter((datum) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(datum.tenantId) : true;

      return tenantMatch;
    });
  }

  let mspCostAlertData = _mspCostAlertData;

  if (tenantDocIDs.length > 0) {
    mspCostAlertData = _mspCostAlertData.filter((datum) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(datum.tenantId) : true;

      return tenantMatch;
    });
  }

  const totalGrossSpend = mspSpendData.reduce((accum, datum) => {
    return accum + datum.billedCost;
  }, 0);

  const totalAnomalousSpend = mspCostAlertData.reduce((accum, datum) => {
    return accum + datum.totalDelta;
  }, 0);

  const totalAnomalousSpendPercent = totalAnomalousSpend / totalGrossSpend;

  //
  // Side Effects
  //

  useEffect(() => {
    if (!state.selectedAlert?.id) return;

    const appliedAlertRule = assignAppliedAlertRule();

    if (!appliedAlertRule) return;

    const dateRange = getAnomalyDateRange(
      state.selectedAlert?.eventTime,
      appliedAlertRule.timeGranularity
    );
    const reportConfig: ReportConfig = {
      dataSource: appliedAlertRule.dataSource,
      dimensions: appliedAlertRule.dimensions,
      durationType: state.selectedAlert?.durationType,
      endDate: dateRange[1].toISOString(),
      filters: alertQueryFilter(state.selectedAlert?.dimensions, labelMaps),
      measures: [appliedAlertRule.measure],
      startDate: dateRange[0].toISOString(),
    };

    window.open(
      `${window.location.origin}${paths._reportBuilderNew}?&tenant_id=${state.selectedTenantID}&report_config=${JSON.stringify(reportConfig)}`,
      "_blank"
    );

    mergeState({ selectedAlert: null });
  }, [isLoadingAlertRule, state.selectedAlert?.id]);

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspCostAlertTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
      case MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED: {
        activityTracker.captureAction(
          actions.SELECT_MSP_ALERT_TO_INVESTIGATE_IN_TRE,
          { value: interaction.alertID }
        );
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        return interaction.hasAccess
          ? mergeState({
              selectedAlert: {
                durationType: DurationType.CUSTOM,
                dimensions: interaction.dimensions,
                eventTime: interaction.eventTime,
                id: interaction.alertID,
              },
              selectedTenantID: tenant.id,
            })
          : mergeState({
              selectedTenantID: tenant.id,
              showModal: true,
            });
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    const rolesKeyedByName = keyBy(roles, "name");

    const globalGrant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === SYSTEM_TENANT_ID
    ) as GrantEntity; // Always guaranteed to exist here.

    const roleNames = globalGrant.roles.map((role) =>
      convertSnakeCaseToTitleCase(role)
    );

    const roleIDs = roleNames.map((roleName) => rolesKeyedByName[roleName].id);

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      users: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roleIDs,
        },
      ],
    });
  }

  //
  // Helpers
  //

  function assignAppliedAlertRule() {
    let appliedAlertRule:
      | AlertRuleEntity
      | typeof legacyBillingAlertRule
      | typeof legacyBigQueryAlertRule;
    if (
      state.selectedAlert?.id ===
      CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
    ) {
      appliedAlertRule = legacyBillingAlertRule;
    } else if (
      state.selectedAlert?.id ===
      CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML
    ) {
      appliedAlertRule = legacyBigQueryAlertRule;
    } else {
      if (!alertRule) return;
      appliedAlertRule = alertRule;
    }

    return appliedAlertRule;
  }

  //
  // Render
  //

  const isLoading = isLoadingMspResources || isLoadingMspTenants;

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Box>
        <Text appearance="h3" marginBottom={theme.space_md}>
          {copyText.anomaliesTabHeader}
        </Text>
        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          justifyContent="space-between"
          marginBottom={theme.space_lg}
          padding={theme.space_lg}
          width="50%"
        >
          <Flex alignItems="center" direction="column" width={250}>
            <Text marginBottom={theme.space_sm}>
              {copyText.anomaliesKpiHeaderTotalAnomalousSpend}
            </Text>
            {isLoadingCostAlertData ? (
              <EmptyPlaceholder
                height={50}
                loading
                skeletonVariant="bar"
                width={175}
              />
            ) : (
              <Text fontSize={theme.h1_fontSize}>
                {formatCurrencyRounded({
                  currencyCode: "USD",
                  number: totalAnomalousSpend,
                })}
              </Text>
            )}
          </Flex>
          <Flex alignItems="center" direction="column" width={250}>
            <Text marginBottom={theme.space_sm}>
              {copyText.anomaliesKpiHeaderPercentAnomalousSpend}
            </Text>
            {isLoadingSpendData || isLoadingCostAlertData ? (
              <EmptyPlaceholder
                height={50}
                loading
                skeletonVariant="bar"
                width={175}
              />
            ) : (
              <Text fontSize={theme.h1_fontSize}>
                {formatPercentage(totalAnomalousSpendPercent)}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_xs}
        >
          <Flex alignItems="center">
            <Text appearance="h3" marginRight={theme.space_sm}>
              {copyText.sectionHeaderAnomalies}
            </Text>
            {filteredcostAlerts?.length === 0 &&
              searchParamState.t_ids.length > 0 && (
                <Tooltip
                  content={copyText.noAnomaliesAfterFilteringMessage.replace(
                    "%RESOURCE%",
                    copyText.filterTenantsAnomaliesText
                  )}
                  placement="right"
                  width="250px"
                >
                  <IconExclamationDiamond size={"20px"} />
                </Tooltip>
              )}
          </Flex>
          <CSVLink
            data={filteredcostAlerts ?? []}
            headers={csvHeaders}
            filename={`msp-cost-alerts-${format(new Date(), "MM-dd-yyyy")}`}
          >
            <Button
              iconStart={<Icon color="inherit" icon={faFileExport} />}
              secondary
              size="small"
            >
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
        <MspCostAlertTable
          costAlerts={filteredcostAlerts ?? []}
          isLoading={isLoading}
          onInteraction={handleInteraction}
        />
      </Box>
    </Box>
  );
}
