import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { GrantUsersGlobalAccessParams } from "../../../api/internal/InternalApiClient";
import { useInternalApiClient } from "../../../api/internal/InternalApiClientProvider";

type Options = UseMutationOptions<
  string[],
  CoreAPIClientError,
  GrantUsersGlobalAccessParams
>;

type Result = UseMutationResult<
  string[],
  CoreAPIClientError,
  GrantUsersGlobalAccessParams
>;

export function useGrantUsersGlobalAccess(options?: Options): Result {
  const client = useInternalApiClient();

  return useMutation({
    mutationFn: (params) => client.grantUsersGlobalAccess(params),
    ...options,
  });
}
