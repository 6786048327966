import useGatekeeper from "@/hooks/useGatekeeper";
import UserAvatar from "@/ui-lib/components/Avatar";
import { createColumnHelper } from "@tanstack/react-table";
import { Role } from "@ternary/api-lib/constants/roles";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/charts/Table/Table";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import { convertSnakeCaseToTitleCase } from "@ternary/api-lib/utils/CaseUtils";
import { isEmpty, isNil } from "lodash";
import React, { useMemo } from "react";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";

export type User = {
  id: string;
  avatarSrc: string;
  createdAt: string;
  email: string;
  grant: { roles: Role[] };
  firstName: string;
  lastLogin: string | null;
  lastName: string;
};

type TableData = {
  id: string;
  avatarSrc: string;
  createdAt: string;
  email: string;
  lastLogin: string | null;
  name: string;
  roles: Role[];
};

interface Props {
  isLoading: boolean;
  users: User[];
  onInteraction: (interaction: UserTable.Interaction) => void;
}

const columnHelper = createColumnHelper<TableData>();

function UserTable(props: Props): JSX.Element {
  const columns = useMemo(
    () => [
      columnHelper.accessor("avatarSrc", {
        cell: (context) => (
          <UserAvatar src={context.getValue()} height="32px" width="32px" />
        ),
        header: "",
        enableSorting: false,
        size: 50,
      }),
      columnHelper.accessor("name", {
        header: copyText.userTableHeader_name,
        size: 200,
      }),
      columnHelper.accessor("email", {
        header: copyText.userTableHeader_email,
        size: 200,
      }),
      columnHelper.accessor("roles", {
        cell: ({ row }) =>
          row.original.roles
            .map((role) => convertSnakeCaseToTitleCase(role))
            .join(", "),
        header: copyText.userTableHeader_roles,
        size: 200,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ row }) => {
          if (isNil(row.original.createdAt)) {
            return <>{copyText.notAvailable}</>;
          }

          return (
            <>{formatDate(new Date(row.original.createdAt), "MM/dd/yyyy")}</>
          );
        },
        header: copyText.userTableHeader_createdAt,
        size: 200,
      }),
      columnHelper.accessor("lastLogin", {
        cell: ({ row }) => {
          if (isNil(row.original.lastLogin)) {
            return <>{copyText.notAvailable}</>;
          }

          return <>{new Date(row.original.lastLogin).toLocaleString()}</>;
        },
        header: copyText.userTableHeader_lastLogin,
        size: 200,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <ActionDropdown
            userID={row.original.id}
            onInteraction={props.onInteraction}
          />
        ),
        meta: { align: "center" },
        size: 40,
      }),
    ],
    [props.users]
  );

  const data = useMemo(() => {
    if (props.isLoading) return [];

    return props.users.map((user) => {
      const fullName = getFullName(user);

      return {
        id: user.id,
        avatarSrc: user.avatarSrc,
        createdAt: user.createdAt,
        email: user.email,
        lastLogin: user.lastLogin,
        name: isEmpty(fullName.trim()) ? user.email : fullName,
        roles: user.grant.roles,
      };
    });
  }, [props.users, props.isLoading]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "email", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

interface ActionDropdownProps {
  userID: string;
  onInteraction: (interaction: UserTable.Interaction) => void;
}

function ActionDropdown(props: ActionDropdownProps): JSX.Element {
  const gatekeeper = useGatekeeper();

  const options = [
    {
      label: copyText.actionMenuItemEditUser,
      locked: !gatekeeper.canUpdateGrant,
      onClick: () =>
        props.onInteraction({
          type: UserTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED,
          userID: props.userID,
        }),
    },
    {
      label: copyText.actionMenuItemRevokeUserAccess,
      locked: !gatekeeper.canRevokeUserTenantAccess,
      onClick: () =>
        props.onInteraction({
          type: UserTable.INTERACTION_REVOKE_USER_ACCESS_CLICKED,
          userID: props.userID,
        }),
    },
  ];

  return (
    <Dropdown options={options} placement="bottom-end">
      <ActionMenuButton />
    </Dropdown>
  );
}

UserTable.INTERACTION_REVOKE_USER_ACCESS_CLICKED =
  "UserTable.INTERACTION_REVOKE_USER_ACCESS_CLICKED" as const;
UserTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED =
  "UserTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED" as const;

type InteractionDeleteButtonClicked = {
  type: typeof UserTable.INTERACTION_REVOKE_USER_ACCESS_CLICKED;
  userID: string;
};

type InteractionEditButtonClicked = {
  type: typeof UserTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED;
  userID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace UserTable {
  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionEditButtonClicked;
}

export default UserTable;
