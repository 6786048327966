export function convertSnakeCaseToTitleCase(value: string): string {
  return value
    .toLowerCase()
    .split("_")
    .map((element) => element.charAt(0).toUpperCase() + element.slice(1))
    .join(" ");
}

export function convertTitleCaseToSnakeCase(value: string): string {
  return value
    .toLowerCase()
    .split(" ")
    .map((element) => element.toUpperCase())
    .join("_");
}
