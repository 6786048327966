import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  actionArchiveReallocation: "Deactivate",
  actionCopyReallocation: "Copy",
  actionDeleteReallocation: "Delete",
  actionEditReallocation: "Edit",
  actionExecuteReallocation: "Run Allocation",
  actionUnarchiveReallocation: "Activate",
  createReallocationLabel: "Create Allocation",
  deleteReallocationConfirmationMessage: "Are you sure you want to delete this Allocation?",
  deleteReallocationConfirmationTitle: "Delete Allocation",
  errorCreatingReallocationMessage: "An error occurred while attempting to create the allocation. Please try again.",
  errorDeletingReallocationMessage: "An error occurred while attempting to delete the allocation. Please try again.",
  errorTriggeringReallocationMessage: "An error occurred while attempting to trigger the allocation. Please try again.",
  errorUpdatingReallocationMessage: "An error occurred while attempting to update the allocation. Please try again.",
  latestRefreshTimeNull: "N/A",
  reallocationFailedLastJobTooltipWarning: "The last job started at %TIMESTAMP% failed.",
  reallocationFormAddFilterButtonLabel: "Add",
  reallocationFormAddFilterValueDialogue: "Add \"%VALUE%\"",
  reallocationFormAddLabelButtonLabel: "Add Label",
  reallocationFormAddTargetButtonLabel: "Add Target",
  reallocationFormCannotBeChangedWarning: "Cannot currently be changed.",
  reallocationFormCopyNameLabel: "(copy)",
  reallocationFormDescriptorDateRange: "Only costs within the specified date range will be reallocated.",
  reallocationFormDescriptorDynamicFilters: "Filters further constrain the weighting metric used to determine each target’s share of reallocated cost. For example, targets may be weighted based on their share of the overall cloud costs; but, with filters, targets may be weighted based on their share of costs in a specific project or specific region.",
  reallocationFormDescriptorFilters: "Filters are the criteria for costs to be reallocated. For example, costs with a specific SKU from a specific project may be reallocated.",
  reallocationFormDescriptorFrequency: "One-time reallocations do not recur. Scheduled reallocations run daily, refreshing the current and the most recently closed invoice month on a rolling basis.",
  reallocationFormDescriptorGroupings: "For each new reallocation line item, an inverse line item is credited to the origin to preserve accurate totals across a tenant. Groupings determine the baseline level of detail which will be present on the reallocation line items, and their inverses.",
  reallocationFormDescriptorMinimumShare: "Costs will be reallocated to targets in proportion to each target’s share of the selected Measure. If Minimum Share is set, targets with a negligible share of the reallocated cost will be exempted from reallocation altogether.",
  reallocationFormDescriptorSourceMatchKeys: "In dynamically weighted reallocations, targets are identified by unique combinations of values for the keys selected below. The reallocation line items will inherit the detail specified by these weighting keys.",
  reallocationFormDescriptorTargets: "[Optional with Dynamic Weighting on projectId] Which targets should receive reallocated charges? The reallocation line items will inherit the details defined by the Origin Groupings, as well as the Weighting Keys if dynamic weighting is selected (the latter has precedence). Declaring explicit targets can enrich the reallocation line items with additional detail.",
  reallocationFormDescriptorType: "Should the reallocation weights be static or dynamic? Statically weighted reallocations always distribute costs to targets in fixed proportions, configured below. Dynamically weighted reallocations distribute costs in proportion to each target’s share of some metric.",
  reallocationFormDimensionsMissingSourceMatchKeysWarning: "Dimensions must include selected source match keys",
  reallocationFormDynamicLabel: "Dynamically Weighted",
  reallocationFormEndDateLabel: "End Date (Optional)",
  reallocationFormFiltersLabel: "Filters",
  reallocationFormGroupingsLabel: "Groupings",
  reallocationFormHeaderCostDestination: "Cost Destination",
  reallocationFormHeaderCostOrigin: "Cost Origin",
  reallocationFormkeyOptionCreateDialogue: "Add key %input%",
  reallocationFormMeasureLabel: "Measure",
  reallocationFormMinimumShareLabel: "Minimum Share",
  reallocationFormMinimumShareWarning: "Minimum share must be greater than zero.",
  reallocationFormMoreOptionsLabel: "More...",
  reallocationFormNameLabel: "Name",
  reallocationFormNullValueOption: "Null",
  reallocationFormProjectLabel: "Project",
  reallocationFormRebuildTypeLabel: "Rebuild Frequency",
  reallocationFormRemoveTargetButtonLabel: "Remove Target",
  reallocationFormSelectFilterNameLabel: "Select Label...",
  reallocationFormSelectLabelLabel: "Select Label...",
  reallocationFormSelectValueLabel: "Select Values...",
  reallocationFormShareInputDescription: "Share values must total up to 100%.",
  reallocationFormShareLabel: "Share",
  reallocationFormSourceLabel: "Source",
  reallocationFormSourceMatchKeyBundleHelperClickText: "Click here to add them.",
  reallocationFormSourceMatchKeyBundleHelperText: "Your weighting keys include %KEY% which is related to the following dimensions: %DIMENSIONS%. Click here to add them.",
  reallocationFormSourceMatchKeysLabel: "Weighting keys",
  reallocationFormStartDateLabel: "Start Date",
  reallocationFormStaticLabel: "Statically Weighted",
  reallocationFormTargetLabelsLabel: "Labels",
  reallocationFormTargetsLabel: "Targets",
  reallocationFormTypeLabel: "Type",
  reallocationFormUnfinishedTargetsWarning: "At least one target is required.",
  reallocationManagemenTableHeader_status: "Status",
  reallocationManagementTableHeader_createdBy: "Created By",
  reallocationManagementTableHeader_id: "ID",
  reallocationManagementTableHeader_lastSuccessfulJob: "Last Successful Job",
  reallocationManagementTableHeader_name: "Name",
  reallocationManagementTableHeader_rebuildType: "Allocation Type",
  reallocationManagementTableHeader_status: "Status",
  reallocationManagementTableNA: "N/A",
  reallocationRebuildTypeOneoff: "One Time",
  reallocationRebuildTypeOneoffLabel: "One Time",
  reallocationRebuildTypeScheduled: "Scheduled",
  reallocationRebuildTypeScheduledLabel: "Scheduled",
  reallocationTabLabelAllocationAssistant: "Allocation Assistant",
  reallocationTabLabelAllocationRules: "Allocation Rules",
  reallocationTabLabelManuallyAllocate: "Manually Allocate",
  reallocationTableTooltipActive: "Active",
  reallocationTableTooltipInactive: "Inactive",
  reallocationTriggerButtonDisabledInactiveTooltipText: "Reactivate this allocation to manually trigger a job.",
  reallocationTriggerButtonDisabledInProgressTooltipText: "This reallocation was triggered on %TIMESTAMP% and is still being executed.",
  reallocationTriggerButtonEnabledTooltipText: "Run allocation manually",
  sideDrawerTitleCreate: "Create",
  sideDrawerTitleEdit: "Edit",
  successCreatingReallocationMessage: "The allocation has been successfully created.",
  successDeletingReallocationMessage: "The allocation has been successfully deleted.",
  successTriggeringReallocationMessage: "The allocation was triggered successfully",
  successUpdatingReallocationMessage: "The allocation has been successfully updated.",
};
