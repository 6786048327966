import { useTheme } from "@emotion/react";
import { faChartLine, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  add,
  format,
  getDaysInMonth,
  isBefore,
  setDate,
  startOfDay,
} from "date-fns";
import React, { useMemo } from "react";
import {
  Area,
  AreaChart,
  Tooltip as ChartTooltip,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import { roundDate } from "../../analytics/utils";
import { UnitType } from "../../constants/analytics";
import { BudgetThresholdWarningType } from "../../constants/enums";
import Box from "../components/Box";
import EmptyPlaceholder from "../components/EmptyPlaceholder";
import Flex from "../components/Flex";
import Text from "../components/Text";
import Tooltip from "../components/Tooltip";
import timing from "../constants/timing";
import copyText from "../copyText";
import { formatDate } from "../utils/dates";
import { formatCurrencyRounded } from "../utils/formatNumber";
import { ChartWrapper } from "./ChartWrapper";
import SimpleChartTooltip from "./SimpleChartTooltip";
import {
  formatMeasureValueWithUnit,
  formatTimestamp,
  getTicks,
  REPORT_PDF_CHART_HEIGHT,
  REPORT_PDF_CHART_WIDTH,
} from "./utils";

type BudgetSpendDatum = {
  cost?: number;
  customNetCost?: number;
  netCost?: number;
  timestamp: string;
};

type ApplicableCost = BudgetSpendDatum & { applicableCost: number };

type BudgetScope = {
  key: string;
  values: string[];
};

type BudgetThreshold = {
  percent: number;
  warningType?: BudgetThresholdWarningType;
};

type BudgetStatus = {
  forecasted: number;
};

type Budget = {
  amount: {
    specifiedAmount: { amount: number } | null;
    variableAmount: Record<string, never> | null;
  };
  measure: string;
  periodVersions: PeriodVersion[];
  scopes?: BudgetScope[];
  status: BudgetStatus | null;
  thresholds: BudgetThreshold[];
};

type PeriodVersion = {
  amount: number;
  creationTime: string;
  period: { month: string };
};

type ChartDatum = {
  applicableCost?: number;
  applicableCostOverage?: number;
  forecast?: number;
  forecastOverage?: number;
  timestamp: string;
};

interface Props {
  budget: Budget | null;
  data: BudgetSpendDatum[];
  height: number | string;
  hideTitle?: boolean;
  isServer?: boolean;
  loading: boolean;
}

// NOTE: Leaning on "timestamp" is insufficient if we ever change the granularity (not planned)
export function BudgetsCurrentDailyGraph(props: Props): JSX.Element {
  const theme = useTheme();

  const now = roundDate(new Date());
  const currentMonth = formatTimestamp(now.toISOString(), "MM/yyyy");

  const measure = useMemo(
    () =>
      (props.budget?.measure ?? "cost") as "cost" | "customNetCost" | "netCost",
    [props.budget?.measure]
  );
  const forecastLabel = useMemo(
    () =>
      copyText.budgetsCurrentDailyGraphForecastLabel.replace(
        "%measure%",
        measure
      ),
    [measure]
  );

  // Actual accumulated spend over the course of this month
  const actualData: ApplicableCost[] = useMemo(() => {
    const thisMonthData = props.data.filter((spend) => {
      const monthNumber = formatTimestamp(spend.timestamp, "MM/yyyy");
      return monthNumber === currentMonth;
    });

    const accumulatedSpend = thisMonthData.reduce(
      (accum: ApplicableCost[], spend, i) => {
        const previousEntry = accum[i - 1];

        const applicableCost: number = spend[measure] ?? 0;

        const nextEntry: ApplicableCost = {
          ...spend,
          applicableCost,
        };

        if (previousEntry) {
          const totalSpendToDate =
            previousEntry.applicableCost + applicableCost;
          nextEntry.applicableCost = totalSpendToDate;
        }

        accum.push(nextEntry);

        return accum;
      },
      []
    );

    return accumulatedSpend;
  }, [props.data, measure]);

  const daysInMonth = getDaysInMonth(now);
  const daysWithData = actualData.length;
  const daysWithoutData = daysInMonth - daysWithData;
  const currentSpendTotal =
    actualData.length > 0
      ? actualData[actualData.length - 1].applicableCost
      : 0;
  const forecastDailyGrowth =
    props.budget && props.budget.status
      ? (props.budget.status.forecasted - currentSpendTotal) / daysWithoutData
      : 0;

  const budgetAmount = props.budget
    ? getAmountFromBudget(props.budget) || Infinity
    : Infinity;

  // Combines actual accumulated spend with forecasted spend
  const data: ChartDatum[] = useMemo(() => {
    const output: ChartDatum[] = [];

    if (!props.data || props.data.length === 0) {
      return output;
    }

    for (let i = 0; i < daysInMonth; i++) {
      const first = roundDate(startOfDay(setDate(now, 1)));
      const date = add(first, { days: i });

      let cost = 0;
      let forecast = 0;
      let forecastOverage = 0;
      let costOverage = 0;

      if (i === daysWithData - 1) {
        forecast = currentSpendTotal;
      }
      if (i > daysWithData - 1) {
        const daysIntoForecast = i + 1 - daysWithData;
        forecast = currentSpendTotal + daysIntoForecast * forecastDailyGrowth;
      } else {
        cost = actualData[i].applicableCost;
      }

      if (forecast > budgetAmount) {
        forecastOverage = forecast - budgetAmount;
        forecast = budgetAmount;
      }

      if (cost > budgetAmount) {
        costOverage = cost - budgetAmount;
        cost = budgetAmount;
      }

      output.push({
        timestamp: date.toISOString(),
        ...(cost > 0
          ? { applicableCost: cost }
          : { applicableCost: undefined }),
        ...(costOverage > 0
          ? { applicableCostOverage: costOverage }
          : { applicableCostOverage: undefined }),
        ...(forecast > 0 ? { forecast } : { forecast: undefined }),
        ...(forecastOverage > 0
          ? { forecastOverage }
          : { forecastOverage: undefined }),
      });
    }
    return output;
  }, [actualData, props.budget]);

  const accumulatedSpendKeyedByDate = useMemo(
    () =>
      data.reduce(
        (accum, datum) => {
          const timestamp = roundDate(new Date(datum.timestamp)).toISOString();

          // Add budget alert thresholds to render in the tooltip
          let alertThresholds: { [alertName: string]: number } = {};

          if (props.budget?.thresholds) {
            alertThresholds = props.budget?.thresholds.reduce(
              (obj, item, idx) => ({
                ...obj,
                [`${copyText.budgetsCurrentDailyGraphAlertTooltip} ${idx + 1}`]:
                  budgetAmount * (item.percent / 100),
              }),
              {}
            );
          }

          if (datum.applicableCost) {
            return {
              ...accum,
              [timestamp]: {
                [measure]: datum.applicableCost,
                ...alertThresholds,
              },
            };
          }
          if (datum.forecast) {
            const forecast = datum.forecast + (datum.forecastOverage || 0);
            return {
              ...accum,
              [timestamp]: {
                [forecastLabel]: forecast,
                ...alertThresholds,
              },
            };
          }

          return accum;
        },
        {} as Record<string, { [key: string]: number }>
      ),
    [data, measure, forecastLabel]
  );

  const shouldRenderChart = data.length > 0 && props.budget && !props.loading;

  const handleMonthSpendWarning = () => {
    if (props.budget) {
      return props.budget.thresholds.map((threds, index) => {
        if (threds.warningType !== BudgetThresholdWarningType.DAILY_SPEND) {
          const wanrningAmount = budgetAmount * (threds.percent / 100);
          return (
            <ReferenceLine
              key={index}
              y={wanrningAmount}
              stroke={theme.feedback_negative}
              strokeWidth={2}
              strokeDasharray="4 4"
            />
          );
        }
      });
    }
  };

  return (
    <Box height={props.hideTitle ? "100%" : "90%"} width="100%">
      {!props.hideTitle && (
        <Flex
          alignItems="center"
          marginBottom={theme.space_xs}
          marginLeft={theme.space_md}
        >
          <Text appearance="h4">{copyText.budgetsCurrentDailyGraphTitle}</Text>
          <Tooltip
            content={copyText.budgetsCurrentDailyGraphTooltip}
            icon={faInfoCircle}
            width={"12rem"}
          />
        </Flex>
      )}
      {!shouldRenderChart && (
        <EmptyPlaceholder
          loading={props.loading}
          icon={faChartLine}
          small
          skeletonVariant="cartesian"
        />
      )}
      {shouldRenderChart && (
        <ChartWrapper height={props.height} isServer={props.isServer}>
          <AreaChart
            data={data}
            height={props.isServer ? REPORT_PDF_CHART_HEIGHT : undefined}
            margin={{ bottom: 0, left: 15, right: 15, top: 0 }}
            width={props.isServer ? REPORT_PDF_CHART_WIDTH : undefined}
          >
            <XAxis
              dataKey={"timestamp"}
              minTickGap={10}
              stroke={theme.chart_axis_text}
              tick={{ fontSize: 12 }}
              tickFormatter={(str) => formatDate(new Date(str), "M/dd")}
              ticks={props.isServer ? getTicks(data, "timestamp") : undefined}
              tickSize={8}
            />
            <YAxis
              padding={{ top: 20 }}
              stroke={theme.chart_axis_text}
              tick={{ fontSize: 12 }}
              tickFormatter={(number) => formatCurrencyRounded({ number })}
              tickSize={8}
            />
            <ChartTooltip
              content={(e) => {
                const label = e.label && formatDate(new Date(e.label), "M/dd");
                const entry =
                  (e.label && accumulatedSpendKeyedByDate[e.label]) ?? {};

                const customColors: { [key: string]: string } = {
                  [measure]: theme.budgets_chart_fill_actual,
                  [forecastLabel]: theme.budgets_chart_fill_actual,
                };

                const budgetThresholdGroupings: string[] =
                  props.budget?.thresholds.map((_, idx) => {
                    customColors[
                      `${copyText.budgetsCurrentDailyGraphAlertTooltip} ${idx + 1}`
                    ] = theme.feedback_negative;
                    return `${copyText.budgetsCurrentDailyGraphAlertTooltip} ${idx + 1}`;
                  }) ?? [];

                return (
                  <SimpleChartTooltip
                    customColorsKeyedByGrouping={customColors}
                    label={label}
                    entry={entry}
                    excludedGroupings={[]}
                    reverseSortedGroupings={[
                      ...budgetThresholdGroupings,
                      measure,
                      forecastLabel,
                    ]}
                    formatter={(e) => {
                      return formatMeasureValueWithUnit({
                        unit: UnitType.CURRENCY,
                        value: e,
                      });
                    }}
                    hideTotal
                  />
                );
              }}
            />
            <Area
              animationDuration={timing.chartAnimationDuration}
              dataKey={"applicableCost"}
              fill={theme.budgets_chart_fill_actual}
              fillOpacity={1}
              stackId="a"
              strokeWidth={0}
              type="linear"
            />
            <Area
              animationDuration={timing.chartAnimationDuration}
              dataKey="applicableCostOverage"
              dot={false}
              fill={theme.budgets_chart_fill_overage_actual}
              fillOpacity={1}
              stackId="a"
              strokeWidth={0}
              type="linear"
            />
            <Area
              animationDuration={timing.chartAnimationDuration}
              dataKey="forecast"
              dot={false}
              fill={theme.budgets_chart_fill_underspend}
              fillOpacity={0.5}
              stackId="f"
              stroke={theme.budgets_chart_stroke}
              strokeWidth={0}
              type="linear"
            />
            <Area
              animationDuration={timing.chartAnimationDuration}
              dataKey="forecastOverage"
              dot={false}
              fill={theme.budgets_chart_fill_overage_forecast}
              fillOpacity={0.5}
              stackId="f"
              strokeWidth={0}
              type="linear"
            />
            <ReferenceLine
              y={budgetAmount}
              stroke={theme.budgets_chart_reference_line}
              strokeWidth={3}
            />
            {handleMonthSpendWarning()}
          </AreaChart>
        </ChartWrapper>
      )}
    </Box>
  );
}

export function getAmountFromBudget(budget: Budget): number | null {
  if (budget.amount.variableAmount) {
    if (!budget.periodVersions) return null;

    const now = roundDate(new Date());
    const thisMonthString = format(add(now, { days: 1 }), "yyyy-MM");

    const thisMonthsVersions = budget.periodVersions.filter(
      (version) => version.period.month === thisMonthString
    );

    const amount = getLatestAmountForMonth(thisMonthsVersions);
    return amount;
  }

  return budget.amount.specifiedAmount
    ? budget.amount.specifiedAmount.amount
    : null;
}

export function getLatestVersionForMonth(
  versions: PeriodVersion[]
): PeriodVersion | null {
  if (!versions || versions.length === 0) return null;

  const sorted = versions.sort((a, b) => {
    if (
      isBefore(
        new Date(a.creationTime as string),
        new Date(b.creationTime as string)
      )
    ) {
      return 1;
    }
    return -1;
  });

  return sorted[0];
}

export function getLatestAmountForMonth(
  versions: PeriodVersion[]
): number | null {
  if (!versions || versions.length === 0) return null;

  return getLatestVersionForMonth(versions)?.amount ?? null;
}
