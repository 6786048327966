import styled from "@emotion/styled";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import React, { useState } from "react";

const StyledButtonGroup = styled("span")<{ theme?: Theme }>(({ theme }) => ({
  button: {
    borderColor: theme.border_color_dark,
    borderStyle: "solid",
  },
  "button:first-of-type": {
    borderRadius: "6px 0 0 6px",
    borderWidth: "1px",
  },

  "button:not(:first-of-type):not(:last-of-type)": {
    borderRadius: 0,
    borderWidth: "1px 0 1px 0",
  },

  "button:last-of-type": {
    borderRadius: "0 6px 6px 0",
    borderWidth: "1px",
  },
}));

type Item<Type> = {
  label: string;
  type: Type;
  width?: number;
};

type Props<Type = unknown> = {
  defaultChecked: number;
  items: readonly Item<Type>[];
  width?: number | string;
  onChange: (value: Type) => void;
};

export function ButtonGroup<Type>(props: Props<Type>): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState(props.defaultChecked ?? 0);

  return (
    <StyledButtonGroup>
      {props.items.map((item, i) => {
        return (
          <Button
            key={`${i}-${item.label}`}
            primary={selectedIndex === i}
            secondary={selectedIndex !== i}
            width={props.width ?? item.width}
            onClick={() => {
              setSelectedIndex(i);
              props.onChange(item.type);
            }}
          >
            {item.label}
          </Button>
        );
      })}
    </StyledButtonGroup>
  );
}

export default ButtonGroup;
