import { DataSource } from "@ternary/api-lib/constants/enums";
import { sortBy } from "lodash";
import { getOrderedCategories } from "./components/GlobalPreferencesManagementContainer";
import { IntegrationStatusVariant } from "./constants";
import { differenceInHours } from "date-fns";
import { Theme } from "@ternary/api-lib/ui-lib/theme/default";

type Option = {
  label: string;
  value: string;
};

type Options = {
  label: string;
  options: Option[];
};

type Preference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

export function groupOptionsByPreferences(
  options: Option[],
  preferences: Preference[],
  dataSource: DataSource,
  isAdmin: boolean
): Option[] | Options[] {
  if (!preferences.some((label) => label.dataSource === dataSource)) {
    return options;
  }

  const dataSourcePreferences = preferences.filter(
    (preference) => preference.dataSource === dataSource
  );

  const allCategories = [
    ...getOrderedCategories(dataSourcePreferences),
    "OTHER",
  ];

  if (allCategories.at(-2) === "HIDDEN") {
    // if hidden is the last user-defined category, put it after other
    allCategories.splice(-2, 1);
    allCategories.push("HIDDEN");
  }

  const dict: Record<string, Option[] | undefined> = Object.fromEntries(
    allCategories.map((category) => [category, []])
  );

  options.forEach((option) => {
    const optionPreference = dataSourcePreferences.find((preference) =>
      preference.values.some((val) => val === option.value)
    );

    const optionCategoryName = optionPreference
      ? optionPreference.category
      : "OTHER";

    let optionCategory = dict[optionCategoryName];

    if (!optionCategory) {
      optionCategory = [];
    }

    optionCategory.push({
      value: option.value,
      label: option.value,
    });
  });

  const groupedOptions: Options[] = [];

  allCategories.forEach((category) => {
    const categoryOptions = dict[category];

    if (!categoryOptions || categoryOptions.length === 0) {
      return;
    }

    if (category === "HIDDEN" && !isAdmin) {
      return;
    }

    groupedOptions.push({
      label: category,
      options: categoryOptions,
    });
  });

  groupedOptions.forEach((optionGroup) => {
    optionGroup.options = sortBy(optionGroup.options, (option) =>
      option.label.toLowerCase()
    );
  });

  return groupedOptions;
}

export function getIntegrationStatus(params: {
  latestRefresh: string | null;
  latestUpstream: string | null;
}): IntegrationStatusVariant {
  if (
    !params.latestRefresh ||
    params.latestRefresh.startsWith("0") ||
    !params.latestUpstream ||
    params.latestUpstream.startsWith("0")
  ) {
    return IntegrationStatusVariant.INCOMPLETE;
  }

  const now = new Date();
  const absoluteRefresh = new Date(params.latestRefresh);
  const absoluteUpstream = new Date(params.latestUpstream);
  const refreshDifference = differenceInHours(now, absoluteRefresh);
  const upstreamDifference = differenceInHours(now, absoluteUpstream);

  let status: IntegrationStatusVariant = IntegrationStatusVariant.DANGER;
  if (refreshDifference <= 8 && upstreamDifference <= 24) {
    status = IntegrationStatusVariant.SUCCESS;
  } else if (refreshDifference <= 24 && upstreamDifference <= 72) {
    status = IntegrationStatusVariant.WARNING;
  }

  return status;
}

export function getIntegrationStatusColor(
  status: IntegrationStatusVariant,
  theme: Theme
): string {
  switch (status) {
    case IntegrationStatusVariant.DANGER:
      return theme.feedback_negative;
    case IntegrationStatusVariant.WARNING:
      return theme.cloud_status_warning;
    case IntegrationStatusVariant.SUCCESS:
      return theme.feedback_positive;
    default:
      return theme.feedback_neutral;
  }
}
