export const dateRangeActions = {
  REMOVE_DATE_RANGE_COMPARE: "REMOVE_DATE_RANGE_COMPARE",
  SELECT_DATE_RANGE_COMPARE_CUSTOM: "SELECT_DATE_RANGE_COMPARE_CUSTOM",
  SELECT_DATE_RANGE_COMPARE_PREVIOUS_PERIOD:
    "SELECT_DATE_RANGE_COMPARE_PREVIOUS_PERIOD",
  SELECT_DATE_RANGE_CUSTOM_FIXED: "SELECT_DATE_RANGE_CUSTOM_FIXED",
  SELECT_DATE_RANGE_CUSTOM_INVOICE: "SELECT_DATE_RANGE_CUSTOM_INVOICE",
  SELECT_DATE_RANGE_CUSTOM_WINDOW: "SELECT_DATE_RANGE_CUSTOM_WINDOW",
  SELECT_DATE_RANGE_DURATION_TYPE: "SELECT_DATE_RANGE_DURATION_TYPE",
} as const;
