import { Operator } from "../constants/enums";
import { ReportEntity } from "../core/types";

export type LabelMap = { [key: string]: string }; // Example: { team: label_9 }

export type BinaryFilter = {
  name: string;
  operator: Operator;
  values: string[];
};

export type UnaryFilter = {
  name: string;
  operator: Operator;
  values?: never | null;
};

export type AndFilter = {
  and: QueryFilter[];
};

export type OrFilter = {
  or: QueryFilter[];
};

export type QueryFilter = BinaryFilter | UnaryFilter | AndFilter | OrFilter;

export type Order = { [key: string]: OrderDirection };

export const OrderDirection = { asc: "asc", desc: "desc" } as const;
export type OrderDirection =
  (typeof OrderDirection)[keyof typeof OrderDirection];

export type RawValue = boolean | number | string | null;

export type RawData = { [key: string]: RawValue };

export type ReportDataConfig = Omit<
  ReportEntity,
  | "id"
  | "tenantID"
  | "createdAt"
  | "createdByID"
  | "favoritedUserIDs"
  | "description"
  | "name"
  | "scope"
  | "subscriptions"
  | "tags"
  | "type"
  | "updatedAt"
  | "updatedByID"
> & { id?: string };

// Temporary to support cube chim
export type CubeUnaryOperator = "set" | "notSet";

export type CubeBinaryOperator =
  | "equals"
  | "notEquals"
  | "contains"
  | "notContains"
  | "startsWith"
  | "notStartsWith"
  | "endsWith"
  | "notEndsWith"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "inDateRange"
  | "notInDateRange"
  | "beforeDate"
  | "beforeOrOnDate"
  | "afterDate"
  | "afterOrOnDate";

export type CubeFilter =
  | CubeBinaryFilter
  | CubeUnaryFilter
  | CubeLogicalOrFilter
  | CubeLogicalAndFilter;

export type CubeLogicalAndFilter = {
  and: CubeFilter[];
};

export type CubeLogicalOrFilter = {
  or: CubeFilter[];
};

export interface CubeBinaryFilter {
  /**
   * @deprecated Use `member` instead.
   */
  dimension?: string;
  member?: string;
  operator: CubeBinaryOperator;
  values: string[];
}

export interface CubeUnaryFilter {
  /**
   * @deprecated Use `member` instead.
   */
  dimension?: string;
  member?: string;
  operator: CubeUnaryOperator;
  values?: never;
}

export type CubeDateRange = string | [string, string];

export type CubeTimeDimensionGranularity =
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year";

export interface CubeTimeDimensionBase {
  dimension: string;
  granularity?: CubeTimeDimensionGranularity;
}

type CubeTimeDimensionComparisonFields = {
  compareDateRange: Array<CubeDateRange>;
  dateRange?: never;
};
export type CubeTimeDimensionComparison = CubeTimeDimensionBase &
  CubeTimeDimensionComparisonFields;

type CubeTimeDimensionRangedFields = {
  dateRange?: CubeDateRange;
};
export type CubeTimeDimensionRanged = CubeTimeDimensionBase &
  CubeTimeDimensionRangedFields;

export type CubeTimeDimension =
  | CubeTimeDimensionComparison
  | CubeTimeDimensionRanged;
