import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { ReportScope } from "@ternary/api-lib/constants/enums";
import systemUser, {
  SYSTEM_TENANT_ID,
} from "@ternary/api-lib/constants/system";
import { ReportEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import { keyBy, sortBy, uniq, uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import {
  ArrayParam,
  BooleanParam,
  DecodedValueMap,
  StringParam,
  useQueryParams,
} from "use-query-params";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import { UpdateReportParameters } from "../../../api/core/types";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import { Option } from "../../../ui-lib/components/SelectDropdown";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import { useDistributeSystemReport } from "../../global-admin/hooks/useDistributeSystemReport";
import { useRemoveSystemReport } from "../../global-admin/hooks/useRemoveSystemReport";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useDeleteReport from "../hooks/useDeleteReport";
import useGetReportsByTenantID from "../hooks/useGetReportsByTenantID";
import useUpdateReport from "../hooks/useUpdateReport";
import useUpdateReports from "../hooks/useUpdateReports";
import ReportFormModal from "./ReportFormModal";
import ReportSubscriptionContainer from "./ReportSubscriptionContainer";
import ReportTable from "./ReportTable";
import ReportsBulkEditForm from "./ReportsBulkEditForm";

const MODAL_DELETE_REPORT = "DELETE_REPORT";
const MODAL_DISTRIBUTE_WARNING = "DISTRIBUTE_WARNING";
const MODAL_EDIT_FORM = "EDIT_FORM";
const MODAL_REMOVE_WARNING = "REMOVE_WARNING";
const MODAL_SUBSCRIBE_REPORT = "MODAL_SUBSCRIBE_REPORT";
const MODAL_UPDATE_CONFIRMATION_MULTIPLE = "UPDATE_CONFIRMATION_MULTIPLE";
const MODAL_UPDATE_CONFIRMATION_SINGLE = "UPDATE_CONFIRMATION_SINGLE";
const MODAL_UPDATE_REPORT = "UPDATE_REPORT";

type Interaction = ReportTable.Interaction | ReportsBulkEditForm.Interaction;

const defaultReports = [];
const defaultUsers = [];

interface State {
  modalKey: string;
  selectedReportIDs: string[];
  selectedReportParams: (UpdateReportParameters & { reportID: string })[];
  showMultiSelect: boolean;
}

const initialState: State = {
  modalKey: "",
  selectedReportIDs: [],
  selectedReportParams: [],
  showMultiSelect: false,
};

type SearchParamState = {
  filterCreatedBy: string[];
  filterFavorites: boolean;
  filterTags: string[];
  filterText: string;
};

const queryParamConfigMap = {
  created_by: ArrayParam,
  favorites: BooleanParam,
  tags: ArrayParam,
  text: StringParam,
};

export default function ReportManagementContainer(props: {
  isInternal?: boolean;
}) {
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = useReferenceIfEqual(getQueryParamsState(queryParams));

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  //
  // Query
  //

  const tenantID = props.isInternal
    ? SYSTEM_TENANT_ID
    : authenticatedUser.tenant.id;

  const enabled = props.isInternal
    ? gatekeeper.canListSystemReports
    : gatekeeper.canListReports;

  const {
    data: _reports = defaultReports,
    isLoading: isLoadingReports,
    refetch: refetchReports,
  } = useGetReportsByTenantID(tenantID, { enabled });

  const { data: users = defaultUsers, isLoading: isLoadingUsers } =
    useGetUsersByTenantID(authenticatedUser.tenant.id);

  //
  // Mutations
  //

  const { isPending: isUpdatingReports, mutate: updateReports } =
    useUpdateReports({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingReportsMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchReports();

        mergeState({
          modalKey: "",
          showMultiSelect: false,
          selectedReportIDs: [],
          selectedReportParams: [],
        });

        activityTracker.captureAction(actions.CLICK_TRE_UPDATE_REPORT_TAGS);

        // If you filter by a tag, then delete all occurrences of it,
        // you cant remove that tag's filter
        setQueryParams({ tags: null });

        postAlert({
          message: copyText.successUpdatingReportsMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  // NOTE: Testing some newer React Query patterns here.
  // Utilizing queryClient as well as optimistic update pattern.
  const { isPending: isUpdatingReport, mutate: updateReport } = useUpdateReport(
    {
      onMutate: async (params) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({ queryKey: queryKeys.allReports });

        // Snapshot the previous value
        const previousReports: ReportEntity[] =
          queryClient.getQueryData(queryKeys.tenantReports(tenantID)) ?? [];

        // Optimistically update to the new value.
        // Currently only using this for favoriting.
        if (params.favoritedUserIDs) {
          queryClient.setQueryData(
            queryKeys.tenantReports(tenantID),
            (currentReports: ReportEntity[] | undefined) => {
              return (currentReports ?? []).map((report) =>
                report.id === params.reportID
                  ? {
                      ...report,
                      favoritedUserIDs: params.favoritedUserIDs ?? [],
                    }
                  : report
              );
            }
          );
        }

        // Return a context object with the snapshotted value
        return { previousReports };
      },
      onError: (_, params, context) => {
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        if (context && params.favoritedUserIDs) {
          queryClient.setQueryData(
            queryKeys.tenantReports(tenantID),
            context.previousReports
          );
        }

        postAlert({
          message: copyText.errorUpdatingReportMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (_, params) => {
        // Don't show the toast when updating favories.
        if (params.favoritedUserIDs) return;

        mergeState({
          modalKey: "",
          showMultiSelect: false,
          selectedReportIDs: [],
          selectedReportParams: [],
        });

        // If you filter by a tag, then delete all occurrences of it,
        // you cant remove that tag's filter
        setQueryParams({ tags: null });

        postAlert({
          message: copyText.successUpdatingReportsMessage,
          type: AlertType.SUCCESS,
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.allReports });
      },
    }
  );

  const {
    isPending: isDistributingSystemReport,
    mutate: distributeSystemReport,
  } = useDistributeSystemReport({
    onError: () => {
      postAlert({
        message: copyText.errorDistributingSystemReportMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedReportIDs: [] });

      queryClient.invalidateQueries({ queryKey: queryKeys.allReports });

      postAlert({
        message: copyText.successDistributingSystemReportMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isRemovingSystemReport, mutate: removeSystemReport } =
    useRemoveSystemReport({
      onError: () => {
        postAlert({
          message: copyText.errorRemovingSystemReportMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        mergeState({ modalKey: "", selectedReportIDs: [] });

        queryClient.invalidateQueries({ queryKey: queryKeys.allReports });

        postAlert({
          message: copyText.successRemovingSystemReportMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isDeletingReport, mutate: deleteReport } = useDeleteReport(
    {
      onError: () => {
        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.errorDeletingReportMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchReports();

        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.SUCCESS_REPORT_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy([...users, systemUser], "id");

  const reports = _reports.map((report) => {
    const createdByUser = usersKeyedByID[report.createdByID];
    const updatedByUser = usersKeyedByID[report.updatedByID ?? ""];

    return {
      ...report,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const reportsKeyedByID = keyBy(reports, "id");

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case ReportsBulkEditForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ modalKey: "", selectedReportIDs: [] });
        return;
      }
      case ReportsBulkEditForm.INTERACTION_MULTIPLE_SUBMIT_BUTTON_CLICKED: {
        if (props.isInternal) {
          mergeState({
            modalKey: MODAL_UPDATE_CONFIRMATION_MULTIPLE,
            selectedReportParams: interaction.params,
          });
          return;
        }

        updateReports({ paramsArray: interaction.params });
        return;
      }
      case ReportsBulkEditForm.INTERACTION_SINGLE_SUBMIT_BUTTON_CLICKED: {
        if (props.isInternal) {
          mergeState({
            modalKey: MODAL_UPDATE_CONFIRMATION_SINGLE,
            selectedReportParams: [
              { reportID: interaction.reportID, ...interaction.params },
            ],
          });
          return;
        }

        updateReport({
          reportID: interaction.reportID,
          ...interaction.params,
        });
        return;
      }
      case ReportTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED: {
        mergeState({ modalKey: MODAL_EDIT_FORM });
        return;
      }
      case ReportTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED: {
        mergeState({ selectedReportIDs: interaction.reportIDs });
        return;
      }
      case ReportTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED: {
        mergeState({
          modalKey: MODAL_EDIT_FORM,
          selectedReportIDs: [interaction.reportID],
        });
        return;
      }
      case ReportTable.INTERACTION_FAVORITE_REPORT_CLICKED: {
        const favoritedUserIDs =
          reportsKeyedByID[interaction.reportID].favoritedUserIDs;

        if (!favoritedUserIDs.includes(authenticatedUser.id)) {
          updateReport({
            reportID: interaction.reportID,
            favoritedUserIDs: [...favoritedUserIDs, authenticatedUser.id],
          });
        } else {
          updateReport({
            reportID: interaction.reportID,
            favoritedUserIDs: favoritedUserIDs.filter(
              (id) => id !== authenticatedUser.id
            ),
          });
        }

        return;
      }
      case ReportTable.INTERACTION_FILTER_FAVORITES_CLICKED: {
        setQueryParams({ favorites: !queryParamState.filterFavorites || null });
        return;
      }
      case ReportTable.INTERACTION_DISTRIBUTE_REPORT_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DISTRIBUTE_WARNING,
          selectedReportIDs: [interaction.reportID],
        });
        return;
      }
      case ReportTable.INTERACTION_ROW_CLICKED:
      case ReportTable.INTERACTION_VIEW_BUTTON_CLICKED: {
        const report = reportsKeyedByID[interaction.reportID];

        navigate(
          paths._reportBuilder.replace(":reportID", interaction.reportID),
          { state: { report } }
        );
        return;
      }
      case ReportTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED: {
        mergeState({ showMultiSelect: interaction.display });
        return;
      }
      case ReportTable.INTERACTION_TAG_CLICKED: {
        if (queryParamState.filterTags.includes(interaction.tag)) {
          setQueryParams({
            tags: queryParamState.filterTags.filter(
              (otherTag) => otherTag !== interaction.tag
            ),
          });
        } else {
          setQueryParams({
            tags: [...queryParamState.filterTags, interaction.tag],
          });
        }
        return;
      }
      case ReportTable.INTERACTION_REMOVE_REPORT_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_REMOVE_WARNING,
          selectedReportIDs: [interaction.reportID],
        });
        return;
      }
      case ReportTable.INTERACTION_UPDATE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE_REPORT,
          selectedReportIDs: [interaction.reportID],
        });
        return;
      }
      case ReportTable.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE_REPORT,
          selectedReportIDs: [interaction.reportID],
        });
        return;
      }
      case ReportTable.INTERACTION_SUBSCRIBE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_SUBSCRIBE_REPORT,
          selectedReportIDs: [interaction.reportID],
        });
        return;
      }
    }
  }

  function handleDeleteReport(): void {
    const reportID = state.selectedReportIDs[0];

    if (!reportID) return;

    deleteReport({ reportID });
  }

  function handleUpdateReport(params: {
    description?: string;
    name: string;
    tags: string[];
  }): void {
    const reportID = state.selectedReportIDs[0];

    if (!reportID) return;

    updateReport({
      reportID,
      name: params.name,
      description: params.description,
      tags: params.tags,
    });
  }

  const filteredReports = useMemo(() => {
    let filteredReports = reports;

    if (queryParamState.filterCreatedBy.length > 0) {
      filteredReports = filteredReports.filter((report) => {
        return queryParamState.filterCreatedBy.includes(report.createdByID);
      });
    }

    if (queryParamState.filterFavorites) {
      filteredReports = filteredReports.filter((report) => {
        return report.favoritedUserIDs.includes(authenticatedUser.id);
      });
    }

    if (queryParamState.filterTags.length > 0) {
      filteredReports = filteredReports.filter((report) =>
        queryParamState.filterTags.every((tag) => report.tags.includes(tag))
      );
    }

    if (queryParamState.filterText.length > 0) {
      const searchStr = queryParamState.filterText.toLowerCase();
      filteredReports = filteredReports.filter((report) => {
        const lowerCasedTags = report.tags.map((tag) => tag.toLowerCase());
        return (
          report.createdByID.toLowerCase().includes(searchStr) ||
          report.name.toLowerCase().includes(searchStr) ||
          lowerCasedTags.some((str) => str.includes(searchStr))
        );
      });
    }

    return filteredReports;
  }, [
    reports,
    queryParamState.filterCreatedBy,
    queryParamState.filterFavorites,
    queryParamState.filterText,
  ]);

  const existingTags = useMemo(() => {
    return reports.reduce((accum: string[], report) => {
      report.tags.forEach((tag) => {
        if (!accum.includes(tag)) {
          accum.push(tag);
        }
      });
      return accum;
    }, []);
  }, [reports]);

  const existingTagOptions: Option[] = sortBy(
    existingTags.map((tag) => ({
      label: tag,
      value: tag,
    })),
    (option) => option.value.toLowerCase()
  );

  const highlightedTags = useMemo(
    () =>
      uniq([
        ...queryParamState.filterTags,
        ...existingTags.filter((tag) =>
          queryParamState.filterText.length > 0
            ? tag
                .toLowerCase()
                .includes(queryParamState.filterText.toLowerCase())
            : false
        ),
      ]),
    [existingTags, queryParamState]
  );

  const createdByFilterOptions: Option[] = sortBy(
    uniqBy(reports, "createdByEmail").reduce((accum: Option[], report) => {
      if (!report.createdByEmail) return accum;

      return [
        ...accum,
        {
          label: report.createdByEmail,
          value: report.createdByID,
        },
      ];
    }, []),
    (option) => option.label.toLowerCase()
  );

  const selectedReports = useMemo(() => {
    if (state.modalKey !== MODAL_EDIT_FORM) return [];

    return state.selectedReportIDs
      .filter((id) => reportsKeyedByID[id])
      .map((id) => reportsKeyedByID[id]);
  }, [reports, state.modalKey, state.selectedReportIDs]);

  //
  // Render
  //

  function renderModalComponent() {
    switch (state.modalKey) {
      case MODAL_UPDATE_CONFIRMATION_MULTIPLE: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingReports}
            message={copyText.modalMessageUpdateSystemReports}
            title={copyText.modalTitleEditSystemReports}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedReportParams: [],
              })
            }
            onConfirm={() =>
              updateReports({ paramsArray: state.selectedReportParams })
            }
          />
        );
      }
      case MODAL_UPDATE_CONFIRMATION_SINGLE: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingReport}
            message={copyText.modalMessageUpdateSystemReport}
            title={copyText.modalTitleEditSystemReport}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedReportParams: [],
              })
            }
            onConfirm={() => updateReport(state.selectedReportParams[0])}
          />
        );
      }
      case MODAL_EDIT_FORM: {
        return (
          <ReportsBulkEditForm
            existingTags={existingTags}
            isOpen
            loadingSubmit={isUpdatingReports}
            selectedReports={selectedReports}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_DISTRIBUTE_WARNING: {
        return (
          <ConfirmationModal
            isLoading={isDistributingSystemReport}
            message={copyText.modalMessageDistributeReport}
            title={copyText.modalTitleDistributeReport}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedReportIDs: [],
                selectedReportParams: [],
              })
            }
            onConfirm={() => distributeSystemReport(state.selectedReportIDs[0])}
          />
        );
      }
      case MODAL_DELETE_REPORT: {
        return (
          <ConfirmationModal
            isLoading={isDeletingReport}
            message={copyText.deleteReportConfirmationMessage}
            title={copyText.deleteReportConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteReport}
          />
        );
      }
      case MODAL_REMOVE_WARNING: {
        return (
          <ConfirmationModal
            isLoading={isRemovingSystemReport}
            message={copyText.modalMessageRemoveSystemReport}
            title={copyText.modalTitleRemoveReport}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedReportIDs: [],
                selectedReportParams: [],
              })
            }
            onConfirm={() => removeSystemReport(state.selectedReportIDs[0])}
          />
        );
      }
      case MODAL_SUBSCRIBE_REPORT: {
        const reportID = state.selectedReportIDs[0];
        const report = reportsKeyedByID[reportID];

        if (!report) return;

        return (
          <ReportSubscriptionContainer
            isLoadingReport={isLoadingReports}
            report={report}
            users={users}
            refetch={refetchReports}
            onClose={() => mergeState({ modalKey: "" })}
          />
        );
      }
      case MODAL_UPDATE_REPORT: {
        const reportID = state.selectedReportIDs[0];
        const report = reportsKeyedByID[reportID];
        const isGlobalReport = report?.scope === ReportScope.GLOBAL;

        if (!report) return;

        return (
          <ReportFormModal
            existingTags={existingTags}
            isGlobalReport={isGlobalReport}
            isProcessing={isUpdatingReport}
            report={report}
            title={copyText.modalTitleEditReport}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleUpdateReport}
          />
        );
      }
    }
  }

  return (
    <>
      <Flex justifyContent="space-between" marginBottom={theme.space_md}>
        {renderModalComponent()}
        <Box>
          <Text appearance="h2">{copyText.reportsPageTitle}</Text>
        </Box>

        <Flex alignItems="flex-end">
          <Flex>
            <Flex width="12rem">
              <TextInput
                height="100%"
                iconStart={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchReportsDashboardsPlaceholder}
                value={queryParamState.filterText}
                onChange={(event) =>
                  setQueryParams({
                    text: event.target.value ? event.target.value : null,
                  })
                }
              />
            </Flex>

            {existingTagOptions.length > 0 && (
              <Flex marginLeft={theme.space_md}>
                <SelectCheckbox
                  height="100%"
                  options={existingTagOptions}
                  placeholder={copyText.filterReportsByTag}
                  selectedValues={queryParamState.filterTags}
                  width="12rem"
                  onChange={(event) =>
                    setQueryParams({ tags: event.length !== 0 ? event : null })
                  }
                />
              </Flex>
            )}

            <Flex marginHorizontal={theme.space_md}>
              <SelectCheckbox
                height="100%"
                options={createdByFilterOptions}
                placeholder={copyText.filterReportsByOwnerPlaceholder}
                selectedValues={queryParamState.filterCreatedBy}
                width="12rem"
                onChange={(event) =>
                  setQueryParams({
                    created_by: event.length !== 0 ? event : null,
                  })
                }
              />
            </Flex>

            <Box>
              <Button
                iconStart={<Icon icon={faPlus} />}
                locked={!gatekeeper.canCreateDashboards}
                primary
                onClick={() => navigate(paths._reportBuilderNew)}
              >
                {copyText.createReportButtonLabel}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <ReportTable
        existingTags={existingTags}
        highlightedTags={highlightedTags}
        isInternal={props.isInternal}
        isLoading={isLoadingReports || isLoadingUsers}
        reports={filteredReports}
        selectedReportIDs={state.selectedReportIDs}
        showMultiSelect={state.showMultiSelect}
        onInteraction={handleInteraction}
      />
    </>
  );
}

function getQueryParamsState(
  queryParams: DecodedValueMap<typeof queryParamConfigMap>
): SearchParamState {
  return {
    filterCreatedBy: removeNull(queryParams.created_by ?? []),
    filterFavorites: queryParams.favorites ?? false,
    filterTags: removeNull(queryParams.tags ?? []),
    filterText: queryParams.text ?? "",
  };
}

function removeNull(withNull: (string | null)[]): string[] {
  const withoutNull: string[] = [];

  withNull.forEach((tag) => {
    if (tag !== null) withoutNull.push(tag);
  });

  return withoutNull;
}
