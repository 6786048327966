import common from "../../common.copyText";

// prettier-ignore
export default {
  ...common,
  activitiyNoDataPlaceholderText: "No activity to display",
  activityAssigneeChangeText: "changed the <b>Assignees</b>",
  activityAssigneeCommentText: "commented",
  activityAssigneeStatusChangeText: "updated the <b>Status</b>",
  activityAssigneeStatusUnassignedText: "Unassigned",
  activityCommentPlaceholderText: "Write a comment...",
  activityListStatusDetailsText: "<b>%username%</b> %verb%",
  addAssigneeButtonLabel: "Add Assignee",
  budgetsDateCapturedLabel: "Date Captured: ",
  budgetsMeasureLabel: "Measure: ",
  buttonGroupAllCases: "All Cases",
  buttonGroupClosedCases: "Closed Cases",
  buttonGroupMyCases: "My Cases",
  buttonLabelCreateCase: "Create Case",
  caseManagemenetContainerHeaderStatus: "Status",
  caseManagementContainerFilterTitle: "Filters",
  caseManagementContainerHeaderAssignees: "Assignee/s",
  caseManagementContainerHeaderCreatedBy: "Created By",
  caseManagementTableFollowingCaption: "Following",
  caseManagementTableUnassignedLabel: "Unassigned",
  caseManagementTableViewCaseCaption: "View Case",
  caseTypeInputLabel: "Type",
  caseType_INVESTIGATION: "Investigation",
  caseType_OPTIMIZATION: "Optimization",
  caseType_TASK: "Task",
  caseViewActivitySectionTitle: "Activity",
  caseViewAssigneeSectionTitle: "Assignee/s",
  caseViewContentSectionTitle: "Content",
  caseViewCreatedAtSubTitle: "Created %distance% ago",
  caseViewDescriptionSectionTitle: "Description",
  caseViewEditButtonLabel: "Edit",
  caseViewFollowingSectionTitle: "Following",
  caseViewFormFieldDescriptionPlaceholder: "Write a description",
  caseViewFormFieldLabel: "Case Title",
  caseViewFormFieldUntitledPlaceholder: "Untitled Case",
  caseManagementTypeFilterPlaceholer: "Filter type",
  closeCaseConfirmationTitle: "Close Cases",
  closeCaseModalMessage: "Are you sure you want to close %Count% Cases?",
  selectedCasesButtonLabel: "Close Selected Cases",
  selectedCaseExistingResourceWarningTitle: "Existing Case",
  selectedCaseExistingResourceWarningMessage: "A case with this resource already exists. Are you sure you want to create this Case?",
  changeReportButtonLabel: "Change Report",
  changeResourceButtonLabel: "Change Resource",
  closeButtonLabel: "Close Case",
  deleteCaseLabel: "Delete Case",
  deleteCaseWarningText: "Are you sure you want to delete this case? This operation cannot be undone.",
  descriptionInputLabel: "Description",
  dropdownLabelMuteCase: "Mute Case",
  dropdownLabelUnmuteCase: "Unmute Case",
  errorCreatingCaseCommentMessage: "An error occurred while attempting to create the case comment. Please try again.",
  errorCreatingCaseMessage: "An error occurred while attempting to create the case. Please try again.",
  errorLoadingBillingDataMessage: "There was an error loading the requested data. Please refresh the page and try again.",
  errorLoadingIntegrations_message: "There was an error loading integrations. Please refresh the page and try again.",
  errorUpdatingCaseMessage: "An error occurred while attempting to update the case. Please try again.",
  errorUploadingImageMessage: "An error occurred while attempting to upload the image. Please try again.",
  extraAssigneesCaption: "& %count% others",
  externalLinkButtonLabel: "External Link",
  followCaseButtonLabel: "Follow",
  formFieldLabelComment: "Comment",
  formFieldLabelFollowCase: "Follow Case",
  formFieldLabelSelectAssignee: "Select Assignee(s)",
  formFieldLabelSelectCase: "Select Case",
  linkToJiraTooltipCaption: "Create a Jira issue that is synced with this case.",
  linkToJiraTooltipLabel: "Link to Jira",
  nameInputLabel: "Name",
  noActivityPlaceholderText: "No Activity Yet",
  openButtonLabel: "Open Case",
  rampPlanCurrentCommitmentAmount: "Current Commitment",
  rampPlanProjectedVarianceAbsolute: "Projected Variance Absolute",
  rampPlanProjectedVariancePercentage: "Projected Variance Percentage",
  rampPlanCurrentPeriod: "Current Period",
  rampPlanRemainingMonths: "Remaining",
  recommendationDetailsHeaderLinkedAccount: "Linked Account",
  recommendationDetailsHeaderProject: "Project",
  recommendationDetailsHeaderResource: "Resource",
  remainingCountCaption: "& %count% More",
  reportSectionDateRangeLabel: "Date Range",
  reportSectionFiltersLabel: "Filters",
  reportSectionGranularityLabel: "Granularity",
  reportSectionGroupingsLabel: "Groupings",
  reportSectionMeasuresLabel: "Measures",
  reportSectionSourceLabel: "Source",
  reportSectionUnitEconomicsLabel: "Unit Economics",
  selectReportButtonLabel: "Select Report",
  selectResourceButtonLabel: "Select Resource",
  successCreatingCaseCommentMessage: "The case comment has been created successfully.",
  successCreatingCaseMessage: "The case has been created successfully.",
  successUpdatingCaseMessage: "The case has been updated successfully.",
  tabAddToCase: "Add to Case",
  tabCreateCase: "Create Case",
  tableHeaderCaseName: "Name",
  tableHeaderCaseNumber: "Case #",
  tableHeaderCaseType: "Type",
  tableHeaderCaseFollowers: "Followers",
  tableHeaderDateCreated: "Date Created",
  timeDistanceCaption: "%distance% ago",
  tooltipFollowCase: "Do you want to keep tabs on this case?",
  unfollowCaseButtonLabel: "Unfollow",
  unitEconomicsFormulaPlaceHolder: "Formula",
  unnassignedActivityCaption: "Unassigned"
};
