import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { useInternalApiClient } from "../../../api/internal/InternalApiClientProvider";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { userID: string }
>;

type Result = UseMutationResult<string, CoreAPIClientError, { userID: string }>;

export function useRevokeUserGlobalAccess(options?: Options): Result {
  const client = useInternalApiClient();

  return useMutation({
    mutationFn: (params) => client.revokeUserGlobalAccess(params),
    ...options,
  });
}
