import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Permission from "@ternary/api-lib/constants/permissions";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { convertTitleCaseToSnakeCase } from "@ternary/api-lib/utils/CaseUtils";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { keyBy } from "lodash";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { useMspStore } from "../../../lib/zustand";
import TextInput from "../../../ui-lib/components/TextInput";
import { getFullName } from "../../../utils/UserUtils";
import UserForm from "../../admin/components/UserForm";
import UserTable, { User } from "../../admin/components/UserTable";
import useGetGrantsByTenantID from "../../admin/hooks/useGetGrantsByTenantID";
import useGetRolesByTenantID from "../../admin/hooks/useGetRolesByTenantID";
import useUpdateUserTenantRoles from "../../admin/hooks/useUpdateUserTenantRoles";
import copyText from "../copyText";
import useGetGlobalGrantsByParentTenantID from "../hooks/useGetGlobalGrantsByParentTenantID";
import useGetRoles from "../hooks/useGetRoles";
import useGetUsersByIDs from "../hooks/useGetUsersByIDs";
import { useGrantUsersGlobalAccess } from "../hooks/useGrantUsersGlobalAccess";
import { useGrantUsersMspAccess } from "../hooks/useGrantUsersMspAccess";
import { useRevokeUserGlobalAccess } from "../hooks/useRevokeUserGlobalAccess";
import { useRevokeUserMspAccess } from "../hooks/useRevokeUserMspAccess";

type Interaction = UserForm.Interaction | UserTable.Interaction;

interface State {
  searchTerm: string;
  selectedUserID: string | null;
  showForm: boolean;
  showModal: boolean;
}

const initialState: State = {
  searchTerm: "",
  selectedUserID: null,
  showForm: false,
  showModal: false,
};

export default function GlobalGrantManagementContainer(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const location = useLocation();
  const theme = useTheme();

  //
  // State
  //

  const mspStore = useMspStore();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  // If on MSP Admin we only want to get a subset of global users for the
  // specific MSP Partner.

  const isMspAdminLocation = location.pathname.startsWith(paths._mspMgmt);

  const {
    data: _grants = [],
    isLoading: _isLoadingGrants,
    refetch: _refetchGrants,
  } = useGetGrantsByTenantID(SYSTEM_TENANT_ID, {
    enabled: gatekeeper.canReadGrantsSystem && !isMspAdminLocation,
  });

  const {
    data: _mspGrants = [],
    isLoading: _isLoadingMspGrants,
    refetch: _refetchMspGrants,
  } = useGetGlobalGrantsByParentTenantID(
    mspStore.selectedParentTenantID as string,
    {
      enabled:
        gatekeeper.canReadGrantsPartner &&
        !!mspStore.selectedParentTenantID &&
        isMspAdminLocation,
    }
  );

  const grants = isMspAdminLocation ? _mspGrants : _grants;

  const userIDs = grants.map(({ userID }) => userID);

  const {
    data: _users = [],
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useGetUsersByIDs(userIDs, {
    enabled: gatekeeper.canReadUsersSystem || gatekeeper.canReadGrantsPartner,
  });

  const { data: _roles = [], isLoading: _isLoadingRoles } = useGetRoles({
    enabled: !isMspAdminLocation,
  });

  const { data: _mspRoles = [], isLoading: _isLoadingMspRoles } =
    useGetRolesByTenantID(mspStore.selectedParentTenantID as string, {
      enabled: !!isMspAdminLocation,
    });

  //
  // Mutations
  //

  const refetchGrants = isMspAdminLocation ? _refetchMspGrants : _refetchGrants;

  const {
    isPending: isGrantingUsersGlobalAccess,
    mutate: grantUsersGlobalAccess,
  } = useGrantUsersGlobalAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUsersGlobalRolesMessage,
      });
    },
    onSuccess: () => {
      mergeState({ showForm: false });

      refetchUsers();
      refetchGrants();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successGrantingUsersGlobalRolesMessage,
      });
    },
  });

  const { isPending: isGrantingUsersMspAccess, mutate: grantUsersMspAccess } =
    useGrantUsersMspAccess({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorGrantingUsersGlobalRolesMessage,
        });
      },
      onSuccess: () => {
        mergeState({ showForm: false });

        refetchUsers();
        refetchGrants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successGrantingUsersGlobalRolesMessage,
        });
      },
    });

  const {
    isPending: isRevokingUserGlobalAccess,
    mutate: revokeUserGlobalAccess,
  } = useRevokeUserGlobalAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorRevokingUserGlobalRoleMessage,
      });
    },
    onSuccess: () => {
      mergeState({ showModal: false, selectedUserID: null });

      refetchUsers();
      refetchGrants();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successRevokingUserGlobalRolesMessage,
      });
    },
  });

  const { isPending: isRevokingUserMspAccess, mutate: revokeUserMspAccess } =
    useRevokeUserMspAccess({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorRevokingUserGlobalRoleMessage,
        });
      },
      onSuccess: () => {
        mergeState({ showModal: false, selectedUserID: null });

        refetchUsers();
        refetchGrants();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successRevokingUserGlobalRolesMessage,
        });
      },
    });

  const {
    isPending: isUpdatingUserTenantRoles,
    mutate: updateUserTenantRoles,
  } = useUpdateUserTenantRoles({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingUserTenantRolesMessage,
      });
    },
    onSuccess: () => {
      mergeState({ showForm: false, selectedUserID: null });

      refetchGrants();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingUserTenantRolesMessage,
      });
    },
  });

  //
  // Interaction Handlers
  //

  const usersKeyedByEmail = keyBy(_users, "email");

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case UserForm.INTEGRATION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedUserID: null, showForm: false });
        return;
      }
      case UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_CREATE: {
        const users = interaction.params.map((params) => {
          const user = usersKeyedByEmail[params.email];

          return {
            ...params,
            ...(user ? { userID: user.id } : {}),
          };
        });

        if (isMspAdminLocation) {
          grantUsersMspAccess({
            mspTenantID: mspStore.selectedParentTenantID as string, // Guaranteed to exist here
            users,
          });
        } else {
          grantUsersGlobalAccess({ users });
        }

        return;
      }
      case UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        const rolesKeyedByID = keyBy(_roles, "id");

        const roleNames = interaction.roleIDs.map(
          (roleID) => rolesKeyedByID[roleID].name
        );

        const roleSlugs = roleNames.map(convertTitleCaseToSnakeCase);

        updateUserTenantRoles({
          grantID: `${SYSTEM_TENANT_ID}:${interaction.userID}`,
          roles: roleSlugs,
        });
        return;
      }
      case UserTable.INTERACTION_REVOKE_USER_ACCESS_CLICKED: {
        mergeState({ selectedUserID: interaction.userID, showModal: true });
        return;
      }
      case UserTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED: {
        mergeState({ selectedUserID: interaction.userID, showForm: true });
        return;
      }
    }
  }

  function handleRevokeUserGlobalRoles(userID: string) {
    if (isMspAdminLocation) {
      revokeUserMspAccess({
        mspTenantID: mspStore.selectedParentTenantID as string, // Guaranteed to exist here
        userID,
      });
    } else {
      revokeUserGlobalAccess({ userID });
    }
  }

  //
  // Render
  //

  const isLoadingGrants = isMspAdminLocation
    ? _isLoadingMspGrants
    : _isLoadingGrants;

  const isLoadingRoles = isMspAdminLocation
    ? _isLoadingMspRoles
    : _isLoadingRoles;

  const isLoading = isLoadingGrants || isLoadingUsers || isLoadingRoles;

  const grantsKeyedByUserID = keyBy(grants, "userID");

  const users = _users.reduce((accum: User[], user) => {
    const grant = grantsKeyedByUserID[user.id];

    if (!grant) return accum;

    if (state.searchTerm.length > 0) {
      const userSearchStringMatch = [user.email, user.firstName, user.lastName]
        .join("")
        .toLowerCase()
        .includes(state.searchTerm.toLowerCase().replace(/\s/g, ""));

      return userSearchStringMatch ? [...accum, { ...user, grant }] : accum;
    }

    return [...accum, { ...user, grant }];
  }, []);

  const selectedUser = users.find((user) => user.id === state.selectedUserID);

  // If MSP Admin we need to filter out System level roles.
  const roles = isMspAdminLocation
    ? _mspRoles.filter(
        (role) =>
          !role.visibilityPermission ||
          role.visibilityPermission === Permission.VIEW_PARTNER_ROLES
      )
    : _roles;

  const isProcessing =
    isGrantingUsersGlobalAccess ||
    isGrantingUsersMspAccess ||
    isUpdatingUserTenantRoles;

  return (
    <>
      {state.showModal && selectedUser && (
        <ConfirmationModal
          isLoading={isRevokingUserGlobalAccess || isRevokingUserMspAccess}
          message={copyText.revokeUserGlobalRolesConfirmationMessage.replace(
            "%name%",
            getFullName(selectedUser)
          )}
          title={copyText.revokeUserGlobalRolesConfirmationTitle}
          variant="danger"
          onConfirm={() => handleRevokeUserGlobalRoles(selectedUser.id)}
          onCancel={() =>
            mergeState({ selectedUserID: null, showModal: false })
          }
        />
      )}
      {state.showForm && (
        <UserForm
          globalMode
          isOpen
          isProcessing={isProcessing}
          roles={roles}
          selectedUser={selectedUser}
          users={users}
          onInteraction={handleInteraction}
        />
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginVertical={theme.space_md}
      >
        <Flex>
          <Text
            fontSize={theme.h4_fontSize}
          >{`${copyText.userCountLabel} (${users.length})`}</Text>
        </Flex>
        <Flex>
          <Box marginRight={theme.space_md} width={300}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="medium"
              value={state.searchTerm}
              onChange={(event) =>
                mergeState({ searchTerm: event.target.value })
              }
            />
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            secondary
            size="small"
            onClick={() => mergeState({ showForm: true })}
          >
            {copyText.addUsersButtonLabel}
          </Button>
        </Flex>
      </Flex>
      <UserTable
        isLoading={isLoading}
        users={users}
        onInteraction={handleInteraction}
      />
    </>
  );
}
