import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  AlertRuleConditionType,
  AlertRuleStatus,
  DataSource,
} from "@ternary/api-lib/constants/enums";
import { AlertRuleEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { keyBy } from "lodash";
import React, { useMemo, useState } from "react";
import { StringParam, useQueryParams } from "use-query-params";
import useGetDimensionValuesByDataSource from "../../../api/analytics/useGetDimensionValuesByDataSource";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import SideDrawer from "../../../components/SideDrawer";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useAvailableDimensionsByDataSource from "../../../hooks/useAvailableDimensionsByDataSource";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import TextInput from "../../../ui-lib/components/TextInput";
import { getFullName } from "../../../utils/UserUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import { useDebounce } from "../../../utils/timers";
import copyText from "../copyText";
import useCreateAlertRule from "../hooks/useCreateAlertRule";
import useGetAlertRulesByTenantID from "../hooks/useGetAlertRulesByTenantID";
import useGetCostAlertsByTenantID from "../hooks/useGetCostAlertsByTenantID";
import useUpdateAlertRule from "../hooks/useUpdateAlertRule";
import { Action, AlertRule, AlertRuleForm, User } from "./AlertRuleForm";
import AlertRuleTable from "./AlertRuleTable";

interface State {
  searchText: string;
}

const initialState: State = {
  searchText: "",
};

type Interaction = AlertRuleTable.Interaction | AlertRuleForm.Interaction;

const now = new DateHelper();

export default function AlertRuleManagementContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    alertRuleID: StringParam,
    actionPanelKey: StringParam,
  });

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const navigate = useNavigateWithSearchParams();

  //
  // Queries
  //

  const {
    data: rules = [],
    isLoading: isLoadingRules,
    refetch: refetchRules,
  } = useGetAlertRulesByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: costAlerts = [], isLoading: isLoadingCostAlerts } =
    useGetCostAlertsByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const availableDimensions = useAvailableDimensionsByDataSource(
    DataSource.BILLING
  ).map((dimension) => ({ name: dimension, isDate: false }));

  const {
    data: dimensionValuesMap = {},
    isLoading: isLoadingDimensionValuesMap,
  } = useGetDimensionValuesByDataSource({
    dataSource: DataSource.BILLING,
    dateRange: [now.nDaysAgo(365), now.date],
    dimensions: [
      "billingAccountId",
      "projectId",
      "projectName",
      "serviceDescription",
      "skuDescription",
    ],
    measure: "cost",
  });

  //
  // Mutations
  //

  const { isPending: isCreatingRule, mutate: createRule } = useCreateAlertRule({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(
        actions.CLICK_ANOMALY_DETECTION_CREATE_ALERT_RULE
      );
    },
    onSuccess: () => {
      refetchRules();

      setSearchParamState({
        alertRuleID: undefined,
        actionPanelKey: undefined,
      });

      postAlert({
        message: copyText.successCreatingRuleMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isUpdatingRule, mutate: updateRule } = useUpdateAlertRule({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingRuleMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(
        actions.CLICK_ANOMALY_DETECTION_UPDATE_ALERT_RULE
      );
    },
    onSuccess: () => {
      refetchRules();
      setSearchParamState({
        alertRuleID: undefined,
        actionPanelKey: undefined,
      });

      postAlert({
        message: copyText.successUpdatingRuleMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Handlers
  //

  function handleClickCreate(): void {
    setSearchParamState({
      actionPanelKey: Action.CREATE,
    });
  }

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AlertRuleForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        setSearchParamState({
          actionPanelKey: undefined,
          alertRuleID: undefined,
        });
        return;
      }
      case AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_CREATE_ALERT_RULE
        );

        createRule({
          tenantID: authenticatedUser.tenant.fsDocID,
          condition: interaction.condition,
          dataSource: DataSource.BILLING,
          dimensions: interaction.selectedLabels,
          excludeTax: interaction.excludeTax,
          filters: interaction.filters,
          measure: "cost",
          name: interaction.name,
          status: AlertRuleStatus.ACTIVE,
          subscribers: interaction.subscribers,
          timeGranularity: interaction.timeGranularity,
        });
        return;
      }
      case AlertRuleForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        activityTracker.captureAction(
          actions.CLICK_ANOMALY_DETECTION_UPDATE_ALERT_RULE
        );

        if (!searchParamState.alertRuleID) return;

        updateRule({
          alertRuleID: searchParamState.alertRuleID,
          ...(interaction.condition
            ? { condition: { ...interaction.condition } }
            : {}),
          ...(interaction.selectedLabels
            ? { dimensions: interaction.selectedLabels }
            : {}),
          ...(interaction.excludeTax
            ? { excludeTax: interaction.excludeTax }
            : {}),
          ...(interaction.filters ? { filters: interaction.filters } : {}),
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.subscribers
            ? { subscribers: interaction.subscribers }
            : {}),
          ...(interaction.timeGranularity
            ? { timeGranularity: interaction.timeGranularity }
            : {}),
        });
        return;
      }
      case AlertRuleTable.INTERACTION_ARCHIVE_BUTTON_CLICKED: {
        updateRule({
          alertRuleID: interaction.ruleID,
          status: interaction.status,
        });
        return;
      }
      case AlertRuleTable.INTERACTION_COPY_BUTTON_CLICKED: {
        setSearchParamState({
          actionPanelKey: Action.COPY,
          alertRuleID: interaction.ruleID,
        });
        return;
      }
      case AlertRuleTable.INTERACTION_EDIT_BUTTON_CLICKED: {
        setSearchParamState({
          actionPanelKey: Action.UPDATE,
          alertRuleID: interaction.ruleID,
        });
        return;
      }
      case AlertRuleTable.INTERACTION_SELECT_BUTTON_CLICKED: {
        const alertRuleID = interaction.ruleID;
        navigate(paths._alertRuleFeed.replace(":alertRuleID", alertRuleID));
      }
    }
  }

  //
  // Render
  //

  const debouncedSearchText = useDebounce(state.searchText);

  const filteredAlertRules = useMemo(() => {
    return getFilteredAlertRules({
      alertRules: rules ?? [],
      searchText: debouncedSearchText,
      users: users,
    });
  }, [rules, debouncedSearchText, users]);

  // Note: Anomaly detection check to satisfy typescript
  let validatedSelectedAlertRule: AlertRule | undefined;

  const selectedAlertRule = rules.find(
    (rule) =>
      rule.id === searchParamState.alertRuleID &&
      (rule.condition.type === AlertRuleConditionType.ANOMALY_DETECTION ||
        rule.condition.type === AlertRuleConditionType.THRESHOLDING)
  );

  if (
    selectedAlertRule &&
    selectedAlertRule.condition.type ===
      AlertRuleConditionType.ANOMALY_DETECTION
  ) {
    assertAnomalyDetectionRule(selectedAlertRule);
    validatedSelectedAlertRule = selectedAlertRule;
  } else if (
    selectedAlertRule &&
    selectedAlertRule.condition.type === AlertRuleConditionType.THRESHOLDING
  ) {
    assertThresholdRule(selectedAlertRule);
    validatedSelectedAlertRule = selectedAlertRule;
  }

  function renderForm(): JSX.Element | null {
    switch (searchParamState.actionPanelKey) {
      case Action.COPY: {
        return (
          <AlertRuleForm
            action={Action.COPY}
            alertRule={validatedSelectedAlertRule}
            availableDimensions={availableDimensions}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isUpdatingRule || isCreatingRule}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.CREATE: {
        return (
          <AlertRuleForm
            action={Action.CREATE}
            availableDimensions={availableDimensions}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isUpdatingRule || isCreatingRule}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      case Action.UPDATE: {
        return (
          <AlertRuleForm
            action={Action.UPDATE}
            alertRule={validatedSelectedAlertRule}
            availableDimensions={availableDimensions}
            dimensionValuesMap={dimensionValuesMap}
            isLoadingDimensionValuesMap={isLoadingDimensionValuesMap}
            isProcessing={isUpdatingRule || isCreatingRule}
            users={users}
            onInteraction={handleInteraction}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Flex direction="column">
      <Flex
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginTop={theme.space_md}
        padding={theme.space_md}
      >
        <Box width={300} marginRight={theme.space_sm}>
          <TextInput
            iconEnd={
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            }
            placeholder={copyText.searchInputPlaceholder}
            size="large"
            value={state.searchText}
            onChange={(event) => mergeState({ searchText: event.target.value })}
          />
        </Box>
        <Box>
          <Button
            disabled={!gatekeeper.canCreateAlertRules}
            iconStart={<Icon icon={faPlus} />}
            primary
            onClick={handleClickCreate}
          >
            {copyText.createAlertRuleLabel}
          </Button>
        </Box>
      </Flex>

      <Box marginTop={theme.space_md}>
        <AlertRuleTable
          alerts={costAlerts}
          isLoading={isLoadingRules || isLoadingUsers || isLoadingCostAlerts}
          rules={filteredAlertRules}
          users={users}
          onInteraction={handleInteraction}
        />
      </Box>
      <SideDrawer
        isOpen={
          !!searchParamState.actionPanelKey || !!searchParamState.alertRuleID
        }
        title={
          searchParamState.actionPanelKey === Action.UPDATE
            ? copyText.sideDrawerTitleEdit
            : copyText.sideDrawerTitleCreate
        }
        onClose={() => {
          setSearchParamState({
            actionPanelKey: undefined,
            alertRuleID: undefined,
          });
          setSearchParamState({ alertRuleID: undefined });
        }}
        renderContent={() =>
          searchParamState.actionPanelKey ? renderForm() : null
        }
      />
    </Flex>
  );
}

function assertAnomalyDetectionRule(
  rule: AlertRule | AlertRuleEntity
): asserts rule is AlertRule {
  if (rule.condition.type === AlertRuleConditionType.ANOMALY_DETECTION) return;
  throw Error;
}

function assertThresholdRule(
  rule: AlertRule | AlertRuleEntity
): asserts rule is AlertRule {
  if (rule.condition.type === AlertRuleConditionType.THRESHOLDING) return;
  throw Error;
}

function getFilteredAlertRules(params: {
  alertRules: AlertRuleEntity[];
  users: User[];
  searchText: string;
}): AlertRuleEntity[] {
  const usersKeyedByID = keyBy(params.users, "id");

  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.alertRules.filter((alertRule) => {
    if (searchText === "") return true;

    if (alertRule.name.toLowerCase().trim().includes(searchText)) {
      return true;
    }

    if (usersKeyedByID[alertRule.createdByUserID] === undefined) {
      return false;
    }

    if (
      getFullName(usersKeyedByID[alertRule.createdByUserID])
        .toLowerCase()
        .trim()
        .includes(searchText)
    ) {
      return true;
    }

    return false;
  });
}
