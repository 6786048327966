import { useTheme } from "@emotion/react";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  CloudProviderType,
  DurationType,
  RateRecommendationType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import { GrantEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { convertSnakeCaseToTitleCase } from "@ternary/api-lib/utils/CaseUtils";
import { format } from "date-fns";
import { keyBy, sortBy } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { useMspStore } from "../../../lib/zustand";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import SelectCheckbox, {
  Option,
} from "../../../ui-lib/components/SelectCheckbox";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGetRolesByTenantID from "../../admin/hooks/useGetRolesByTenantID";
import { useGrantUsersTenantAccess } from "../../admin/hooks/useGrantUsersTenantAccess";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspChildResources from "../hooks/useGetMspChildResources";
import useGetMspSpendData from "../hooks/useGetMspSpendData";
import { parseArrayParam } from "./MspDashboardPage";
import MspRateRecommendationTable from "./MspRateRecommendationTable";
import MspUsageRecommendationTable from "./MspUsageRecommendationTable";

const rateCsvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "term", label: copyText.tableHeaderTerm },
  { key: "type", label: copyText.tableHeaderType },
  {
    key: "estimatedMonthlySavings",
    label: copyText.tableHeaderPotentialSavingsMonthly,
  },
];

const usageCsvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "serviceType", label: copyText.tableHeaderServiceType },
  { key: "estimateValue", label: copyText.tableHeaderPotentialSavingsMonthly },
];

type Interaction =
  | MspRateRecommendationTable.Interaction
  | MspUsageRecommendationTable.Interaction;

const defaultMspResources = {
  alerts: [],
  alertSummaries: [],
  rateRecommendations: [],
  rateRecommendationSummaries: [],
  usageRecommendations: [],
  usageRecommendationSummaries: [],
};

type State = {
  cloudProviderTypes: string[];
  rateRecTypes: string[];
  selectedTenantID: string | null;
  serviceTypes: string[];
  showModal: boolean;
  terms: string[];
};

const initialState: State = {
  cloudProviderTypes: [],
  rateRecTypes: [],
  selectedTenantID: null,
  serviceTypes: [],
  showModal: false,
  terms: [],
};

interface Props {
  parentTenantID: string;
}

export default function MspRecommendationViewContainer(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState] = useQueryParams({
    t_ids: withDefault(ArrayParam, []),
  });

  const tenantDocIDs = parseArrayParam(searchParamState.t_ids);

  const parentTenantID = useMspStore((state) => state.selectedParentTenantID);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  const {
    data: mspResources = defaultMspResources,
    isLoading: isLoadingMspResources,
  } = useGetMspChildResources(props.parentTenantID);

  const { data: _mspSpendData = [], isLoading: isLoadingSpendData } =
    useGetMspSpendData({
      dateRange: getDateRangeFromDurationType(DurationType.LAST_THIRTY_DAYS),
      dimensions: [],
      parentTenantID: parentTenantID ?? "",
      preAggFilters: [],
    });

  const { data: roles = [] } = useGetRolesByTenantID(
    props.parentTenantID as string
  );

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // Computed Values
  //

  const _rateRecs = mspResources.rateRecommendations.map((rec) => ({
    ...rec,
    tenantName: tenantsKeyedByDocID[rec.tenantDocID]?.name ?? "null",
  }));

  const _usageRecs = mspResources.usageRecommendations.map((rec) => ({
    ...rec,
    tenantName: tenantsKeyedByDocID[rec.tenantDocID]?.name ?? "null",
  }));

  let rateRecs = _rateRecs;

  if (
    tenantDocIDs.length > 0 ||
    state.rateRecTypes.length > 0 ||
    state.terms.length > 0
  ) {
    rateRecs = _rateRecs.filter((rec) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(rec.tenantDocID) : true;

      const rateRecTypesMatch =
        state.rateRecTypes.length > 0
          ? state.rateRecTypes.includes(rec.type)
          : true;

      const termsMatch =
        state.terms.length > 0 ? state.terms.includes(rec.term) : true;

      return tenantMatch && rateRecTypesMatch && termsMatch;
    });
  }

  let usageRecs = _usageRecs;

  if (
    tenantDocIDs.length > 0 ||
    state.cloudProviderTypes.length > 0 ||
    state.serviceTypes.length > 0
  ) {
    usageRecs = _usageRecs.filter((rec) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(rec.tenantDocID) : true;

      const cloudProviderMatch =
        state.cloudProviderTypes.length > 0
          ? state.cloudProviderTypes.includes(rec.cloudProviderType)
          : true;

      const serviceTypeMatch =
        state.serviceTypes.length > 0
          ? state.serviceTypes.includes(rec.serviceType)
          : true;

      return tenantMatch && cloudProviderMatch && serviceTypeMatch;
    });
  }

  let mspSpendData = _mspSpendData;

  if (tenantDocIDs.length > 0) {
    mspSpendData = _mspSpendData.filter((datum) => {
      const tenantMatch =
        tenantDocIDs.length > 0 ? tenantDocIDs.includes(datum.tenantId) : true;

      return tenantMatch;
    });
  }

  const totalMonthlyRateSavings = rateRecs.reduce((accum, rec) => {
    return accum + rec.estimatedMonthlySavings;
  }, 0);

  const totalMonthlyUsageSavings = usageRecs.reduce((accum, rec) => {
    return accum + rec.estimateValue;
  }, 0);

  const totalMonthlySavingsPotential =
    totalMonthlyRateSavings + totalMonthlyUsageSavings;

  const totalMonthlySpend = mspSpendData.reduce((accum, datum) => {
    return accum + datum.billedCost;
  }, 0);

  const percentageTotalMonthlySpend =
    totalMonthlySavingsPotential / totalMonthlySpend;

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspRateRecommendationTable.INTERACTION_LINK_CLICKED:
      case MspUsageRecommendationTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    const rolesKeyedByName = keyBy(roles, "name");

    const globalGrant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === SYSTEM_TENANT_ID
    ) as GrantEntity; // Always guaranteed to exist here.

    const roleNames = globalGrant.roles.map((role) =>
      convertSnakeCaseToTitleCase(role)
    );

    const roleIDs = roleNames.map((roleName) => rolesKeyedByName[roleName].id);

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      users: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roleIDs,
        },
      ],
    });
  }

  //
  // Render
  //

  const cloudProviderTypeOptions = [
    {
      label: copyText.providerTypeLabel_AWS,
      value: CloudProviderType.AWS,
    },
    {
      label: copyText.providerTypeLabel_AZURE,
      value: CloudProviderType.AZURE,
    },
    {
      label: copyText.providerTypeLabel_GCP,
      value: CloudProviderType.GCP,
    },
  ];

  const serviceTypeOptions = sortBy(Object.values(ServiceType), (value) =>
    value.toLowerCase()
  ).reduce(
    (accum: Option[], value) =>
      value === ServiceType.ANMLY
        ? accum
        : [
            ...accum,
            {
              label: copyText[`serviceTypeLabel_${value}`],
              value,
            },
          ],
    []
  );

  const termOptions = [
    { label: copyText.rateRecTermLabel_ONE_YEAR, value: "ONE_YEAR" },
    { label: copyText.rateRecTermLabel_THREE_YEARS, value: "THREE_YEARS" },
  ];

  const rateRecTypeOptions = [
    {
      label: copyText.rateRecTypeLabel_COMMITTED_USE_DISCOUNT,
      value: RateRecommendationType.COMMITTED_USE_DISCOUNT,
    },
    {
      label: copyText.rateRecTypeLabel_RESERVED_INSTANCE_AWS,
      value: RateRecommendationType.RESERVED_INSTANCE_AWS,
    },
    {
      label: copyText.rateRecTypeLabel_RESERVED_INSTANCE_AZURE,
      value: RateRecommendationType.RESERVED_INSTANCE_AZURE,
    },
    {
      label: copyText.rateRecTypeLabel_SAVINGS_PLAN_AWS,
      value: RateRecommendationType.SAVINGS_PLAN_AWS,
    },
    {
      label: copyText.rateRecTypeLabel_SAVINGS_PLAN_AZURE,
      value: RateRecommendationType.SAVINGS_PLAN_AZURE,
    },
  ];

  const isLoading = isLoadingMspResources || isLoadingMspTenants;

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Box>
        <Text appearance="h3" marginBottom={theme.space_md}>
          {copyText.savingsTabHeader}
        </Text>
        <Flex
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          justifyContent="space-between"
          marginBottom={theme.space_lg}
          padding={theme.space_lg}
        >
          <Flex alignItems="center" direction="column" width={250}>
            <Text marginBottom={theme.space_sm}>
              {copyText.savingsKpiHeaderTotalMonthlySavings}
            </Text>
            {isLoadingMspResources ? (
              <EmptyPlaceholder
                height={50}
                loading
                skeletonVariant="bar"
                width={175}
              />
            ) : (
              <Text fontSize={theme.h1_fontSize}>
                {formatCurrencyRounded({
                  currencyCode: "USD",
                  number: totalMonthlySavingsPotential,
                })}
              </Text>
            )}
          </Flex>
          <Flex alignItems="center" direction="column" width={250}>
            <Text marginBottom={theme.space_sm}>
              {copyText.savingsKpiHeaderPercentTotalSpend}
            </Text>
            {isLoadingMspResources || isLoadingSpendData ? (
              <EmptyPlaceholder
                height={50}
                loading
                skeletonVariant="bar"
                width={175}
              />
            ) : (
              <Text fontSize={theme.h1_fontSize}>
                {formatPercentage(percentageTotalMonthlySpend)}
              </Text>
            )}
          </Flex>
          <Flex alignItems="center" direction="column" width={250}>
            <Text marginBottom={theme.space_sm}>
              {copyText.savingsKpiHeaderTotalMonthlyUsageSavings}
            </Text>
            {isLoadingMspResources ? (
              <EmptyPlaceholder
                height={50}
                loading
                skeletonVariant="bar"
                width={175}
              />
            ) : (
              <Text fontSize={theme.h1_fontSize}>
                {formatCurrencyRounded({
                  currencyCode: "USD",
                  number: totalMonthlyUsageSavings,
                })}
              </Text>
            )}
          </Flex>
          <Flex alignItems="center" direction="column" width={250}>
            <Text marginBottom={theme.space_sm}>
              {copyText.savingsKpiHeaderTotalMonthlyRateSavings}
            </Text>
            {isLoadingMspResources ? (
              <EmptyPlaceholder
                height={50}
                loading
                skeletonVariant="bar"
                width={175}
              />
            ) : (
              <Text fontSize={theme.h1_fontSize}>
                {formatCurrencyRounded({
                  currencyCode: "USD",
                  number: totalMonthlyRateSavings,
                })}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width="49%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={theme.space_md}
            >
              <Text appearance="h3" marginRight={theme.space_sm}>
                {copyText.sectionHeaderUsageOptimizations}
              </Text>
              <Flex alignItems="center">
                <Box marginRight={theme.space_sm}>
                  <SelectCheckbox
                    compact
                    hideSearch
                    options={cloudProviderTypeOptions}
                    placeholder="Providers"
                    selectedValues={state.cloudProviderTypes}
                    width={150}
                    onChange={(values) =>
                      mergeState({ cloudProviderTypes: values })
                    }
                  />
                </Box>
                <Box marginRight={theme.space_sm}>
                  <SelectCheckbox
                    compact
                    hideSearch
                    options={serviceTypeOptions}
                    placeholder="Services"
                    selectedValues={state.serviceTypes}
                    width={150}
                    onChange={(values) => mergeState({ serviceTypes: values })}
                  />
                </Box>
                <CSVLink
                  data={usageRecs}
                  headers={usageCsvHeaders}
                  filename={`msp-usage-recs-${format(new Date(), "MM-dd-yyyy")}`}
                >
                  <Button
                    iconStart={<Icon color="inherit" icon={faFileExport} />}
                    secondary
                    size="small"
                  >
                    {copyText.exportButtonLabel}
                  </Button>
                </CSVLink>
              </Flex>
            </Flex>
            <MspUsageRecommendationTable
              isLoading={isLoading}
              recommendations={usageRecs}
              onInteraction={handleInteraction}
            />
          </Box>
          <Box width="49%">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={theme.space_md}
            >
              <Text appearance="h3" marginRight={theme.space_sm}>
                {copyText.sectionHeaderRateOptimizations}
              </Text>
              <Flex alignItems="center">
                <Box marginRight={theme.space_sm}>
                  <SelectCheckbox
                    compact
                    hideSearch
                    options={termOptions}
                    placeholder="Term"
                    selectedValues={state.terms}
                    width={150}
                    onChange={(values) => mergeState({ terms: values })}
                  />
                </Box>
                <Box marginRight={theme.space_sm}>
                  <SelectCheckbox
                    compact
                    hideSearch
                    options={rateRecTypeOptions}
                    placeholder="Type"
                    selectedValues={state.rateRecTypes}
                    width={200}
                    onChange={(values) => mergeState({ rateRecTypes: values })}
                  />
                </Box>
                <CSVLink
                  data={rateRecs ?? []}
                  headers={rateCsvHeaders}
                  filename={`msp-rate-recs-${format(new Date(), "MM-dd-yyyy")}`}
                >
                  <Button
                    iconStart={<Icon color="inherit" icon={faFileExport} />}
                    secondary
                    size="small"
                  >
                    {copyText.exportButtonLabel}
                  </Button>
                </CSVLink>
              </Flex>
            </Flex>
            <MspRateRecommendationTable
              isLoading={isLoading}
              recommendations={rateRecs ?? []}
              onInteraction={handleInteraction}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
