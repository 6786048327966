import { createStructParam } from "@/lib/use-query-params";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls/DateRangeControls";
import copyText from "../../copyText";
import useGetDatabaseSpendSummaries from "../../hooks/useGetDatabaseSpendSummaries";
import useGetAWSDatabaseInstanceGroups from "../hooks/useGetAWSDatabaseInstanceGroups";
import useGetAWSDatabaseInstances from "../hooks/useGetAWSDatabaseInstances";
import useGetAWSDatabaseUsageSummary from "../hooks/useGetAWSDatabaseUsageSummary";
import {
  AWSDatabaseInstanceGroup,
  AWSDatabaseInstanceGroupFilters,
} from "../types";
import {
  default as AWSDatabaseInstanceGroupTable,
  default as RDSInstanceGroupTable,
} from "./AWSDatabaseInstanceGroupTable";
import AWSDatabaseInstanceGroupTableControls from "./AWSDatabaseInstanceGroupTableControls";
import AWSDatabaseInstanceTable from "./AWSDatabaseInstanceTable";
import AWSDatabaseMeters from "./AWSDatabaseMeters";

type Interaction =
  | AWSDatabaseInstanceGroupTable.Interaction
  | AWSDatabaseInstanceGroupTableControls.Interaction;

const AWSDatabaseCostChartOption = {
  DATABASE_TYPE: "DATABASE_TYPE",
  FAMILY: "FAMILY",
  INSTANCE: "INSTANCE",
  LINKED_ACCOUNT: "LINKED_ACCOUNT",
  PRODUCT_FAMILY: "PRODUCT_FAMILY",
  REGION: "REGION",
  STORAGE_TYPE: "STORAGE_TYPE",
} as const;

type AWSDatabaseCostChartOption =
  (typeof AWSDatabaseCostChartOption)[keyof typeof AWSDatabaseCostChartOption];

const AWSDatabaseUsageChartOption = {
  CONNECTIONS: "CONNECTIONS",
  CPU: "CPU",
  DISK: "DISK",
  IOPS: "IOPS",
  MEMORY: "MEMORY",
  NETWORK: "NETWORK",
} as const;

type AWSDatabaseUsageChartOption =
  (typeof AWSDatabaseUsageChartOption)[keyof typeof AWSDatabaseUsageChartOption];
const costGroupingEnum = createEnumParam(
  Object.values(AWSDatabaseCostChartOption)
);
const usageChartOptionEnum = createEnumParam(
  Object.values(AWSDatabaseUsageChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: z.infer<typeof instanceGroupFiltersStruct>;
  selectedCostChartOption: AWSDatabaseCostChartOption;
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  selectedUsageChartOption: AWSDatabaseUsageChartOption;
};

const instanceGroupFiltersDefault = {
  databaseType: null,
  family: null,
  familyType: null,
  lineItemUsageAccountId: null,
  region: null,
};

const instanceGroupFiltersStruct = z.object({
  databaseType: z.nullable(z.string()),
  family: z.nullable(z.string()),
  familyType: z.nullable(z.string()),
  lineItemUsageAccountId: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const selectedInstanceGroupStruct = z.object({
  databaseType: z.string(),
  family: z.string(),
  familyType: z.string(),
  lineItemUsageAccountId: z.string(),
  region: z.string(),
});

const queryParamConfigMap = {
  cost_grouping: withDefault(
    costGroupingEnum,
    AWSDatabaseCostChartOption.LINKED_ACCOUNT
  ),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  filters: withDefault(JsonParam, []),
  instance_table_sort: JsonParam,
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  selected_group: createStructParam(selectedInstanceGroupStruct),
  usage_grouping: withDefault(
    usageChartOptionEnum,
    AWSDatabaseUsageChartOption.CPU
  ),
};

const costGroupingLabel = {
  [AWSDatabaseCostChartOption.DATABASE_TYPE]:
    copyText.awsDatabaseCostChartOptionLabel_databaseType,
  [AWSDatabaseCostChartOption.FAMILY]:
    copyText.awsDatabaseCostChartOptionLabel_family,
  [AWSDatabaseCostChartOption.INSTANCE]:
    copyText.awsDatabaseCostChartOptionLabel_instance,
  [AWSDatabaseCostChartOption.LINKED_ACCOUNT]:
    copyText.awsDatabaseCostChartOptionLabel_linkedAccount,
  [AWSDatabaseCostChartOption.PRODUCT_FAMILY]:
    copyText.awsDatabaseCostChartOptionLabel_productFamily,
  [AWSDatabaseCostChartOption.REGION]:
    copyText.awsDatabaseCostChartOptionLabel_region,
  [AWSDatabaseCostChartOption.STORAGE_TYPE]:
    copyText.awsDatabaseCostChartOptionLabel_storageType,
};

const usageGroupingLabel = {
  [AWSDatabaseUsageChartOption.CONNECTIONS]:
    copyText.awsDatabaseUsageChartOptionLabel_Connections,
  [AWSDatabaseUsageChartOption.CPU]:
    copyText.awsDatabaseUsageChartOptionLabel_cpu,
  [AWSDatabaseUsageChartOption.DISK]:
    copyText.awsDatabaseUsageChartOptionLabel_disk,
  [AWSDatabaseUsageChartOption.IOPS]:
    copyText.awsDatabaseUsageChartOptionLabel_iops,
  [AWSDatabaseUsageChartOption.MEMORY]:
    copyText.awsDatabaseUsageChartOptionLabel_memory,
  [AWSDatabaseUsageChartOption.NETWORK]:
    copyText.awsDatabaseUsageChartOptionLabel_network,
};

const today = new Date();

export default function AWSDatabaseVisibilityContainer(): JSX.Element {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  const queryParamState = getQueryParamState(queryParams);

  let dateRange: Date[] = [];

  const customDateRange =
    (queryParams.duration === DurationType.CUSTOM ||
      queryParams.duration === DurationType.INVOICE) &&
    queryParams.date_range_start &&
    queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : null;

  if (!globalDate && customDateRange) {
    dateRange = customDateRange;
  } else if (globalDate.date) {
    dateRange = globalDate.date;
  } else {
    dateRange = getCubeDateRangeFromDurationType(queryParams.duration);
  }

  //
  // QUERIES
  //

  const spendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.AWS_DATABASE_VISIBILITY,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isFetching
  );

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = ["cost"];

  const { data: latestMemoryUsage, isFetching: isLoadingLatestMemoryUsage } =
    useGetAWSDatabaseUsageSummary();

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.AWS_DATABASE_VISIBILITY,
    dateRange,
    dimensions: costDimensions,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
  });

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption,
    queryParamState.dateRangeGranularity
  );

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.AWS_DATABASE_VISIBILITY,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const { data: instanceGroups, isFetching: isLoadingInstanceGroups } =
    useGetAWSDatabaseInstanceGroups({
      dateRange,
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetAWSDatabaseInstances(
      {
        dateRange: queryParamState.dateRange,
        queryFilters: queryParamState.selectedInstanceGroup
          ? getInstanceGroupQueryFilters(queryParamState.selectedInstanceGroup)
          : [],
      },
      { enabled: Boolean(queryParamState.selectedInstanceGroup) }
    );

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      instanceGroupFilters: queryParamState.instanceGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [
    debouncedSearchText,
    instanceGroups,
    queryParamState.instanceGroupFilters,
  ]);

  const csvData = useMemo(
    () => getCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ instance_group_filters: null });
        } else {
          setQueryParams({ instance_group_filters: nextFilters });
        }
        break;
      }
      case AWSDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  //
  // RENDER
  //

  const costGroupingOptions = [
    AWSDatabaseCostChartOption.DATABASE_TYPE,
    AWSDatabaseCostChartOption.FAMILY,
    AWSDatabaseCostChartOption.INSTANCE,
    AWSDatabaseCostChartOption.LINKED_ACCOUNT,
    AWSDatabaseCostChartOption.PRODUCT_FAMILY,
    AWSDatabaseCostChartOption.REGION,
    AWSDatabaseCostChartOption.STORAGE_TYPE,
  ].map((costGrouping) => ({
    label: costGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setQueryParams({ cost_grouping: costGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === queryParams.cost_grouping
    ) ?? costGroupingOptions[0];

  const usageGroupingOptions = [
    AWSDatabaseUsageChartOption.CPU,
    AWSDatabaseUsageChartOption.MEMORY,
    AWSDatabaseUsageChartOption.NETWORK,
    AWSDatabaseUsageChartOption.CONNECTIONS,
    AWSDatabaseUsageChartOption.DISK,
    AWSDatabaseUsageChartOption.IOPS,
  ].map((usageGrouping) => ({
    label: usageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setQueryParams({ usage_grouping: usageGrouping }),
  }));

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === queryParams.usage_grouping
    ) ?? usageGroupingOptions[0];

  const resourceUsageKeys = useMemo(() => {
    let keys: { [key: string]: string } = {};

    if (queryParamState.dateRangeGranularity === TimeGranularity.HOUR) {
      if (
        queryParamState.selectedUsageChartOption ===
        AWSDatabaseUsageChartOption.MEMORY
      ) {
        return {
          productMemoryBytesMeasure:
            copyText.awsDatabaseResourceUsageProvisioned,
          usedMemoryBytes: copyText.awsDatabaseResourceUsageUsed,
        };
      } else if (
        queryParamState.selectedUsageChartOption ===
        AWSDatabaseUsageChartOption.CPU
      ) {
        return {
          productVCPUMeasure: copyText.awsDatabaseResourceUsageProvisioned,
          usedVCPU: copyText.awsDatabaseResourceUsageUsed,
        };
      }
    } else {
      if (
        queryParamState.selectedUsageChartOption ===
        AWSDatabaseUsageChartOption.MEMORY
      ) {
        return {
          productMemoryBytesChart: copyText.awsDatabaseResourceUsageProvisioned,
          usedMemoryBytesChart: copyText.awsDatabaseResourceUsageUsed,
        };
      } else if (
        queryParamState.selectedUsageChartOption ===
        AWSDatabaseUsageChartOption.CPU
      ) {
        return {
          productVCPUChart: copyText.awsDatabaseResourceUsageProvisioned,
          usedVCPUChart: copyText.awsDatabaseResourceUsageUsed,
        };
      }
    }

    switch (queryParamState.selectedUsageChartOption) {
      case AWSDatabaseUsageChartOption.CONNECTIONS:
        keys = { databaseConnections: copyText.awsDatabaseResourceUsageUsed };
        break;
      case AWSDatabaseUsageChartOption.DISK:
        keys = {
          usedDiskBytes: copyText.awsDatabaseResourceUsageUsed,
        };
        break;
      case AWSDatabaseUsageChartOption.IOPS:
        keys = {
          readIOPS: copyText.awsDatabaseResourceUsageReadIOPS,
          writeIOPS: copyText.awsDatabaseResourceUsageWriteIOPS,
        };
        break;
      case AWSDatabaseUsageChartOption.NETWORK:
        keys = { networkBytes: copyText.awsDatabaseResourceUsageUsed };
        break;
      default:
        keys = {
          usedVCPU: copyText.awsDatabaseResourceUsageUsed,
        };
    }
    return keys;
  }, [queryParamState.selectedUsageChartOption]);

  let formatUsageValue: ((value: string) => string) | undefined = undefined;
  if (
    queryParamState.selectedUsageChartOption ===
    AWSDatabaseUsageChartOption.MEMORY
  ) {
    formatUsageValue = (value) => byteFormatter(value, { binary: true });
  } else if (
    queryParamState.selectedUsageChartOption ===
      AWSDatabaseUsageChartOption.DISK ||
    queryParamState.selectedUsageChartOption ===
      AWSDatabaseUsageChartOption.NETWORK
  ) {
    formatUsageValue = (value) => byteFormatter(value);
  }

  return (
    <Box>
      <AWSDatabaseMeters
        isLoadingSpendSummaries={
          isLoadingSpendSummaries || isLoadingLatestMemoryUsage
        }
        lastMonthSpend={lastMonthFull?.totalCost ?? 0}
        lastMTDSpend={lastMTD?.totalCost ?? 0}
        mtdSpend={currentMTD?.totalCost ?? 0}
        provisionedMemoryBytes={latestMemoryUsage?.productMemoryBytes ?? 0}
        usedMemoryBytes={latestMemoryUsage?.usedMemoryBytes ?? 0}
      />
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParams.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={today}
          onChangeDateRange={(durationType, dateRange) => {
            setQueryParams({
              duration: durationType,
              date_range_start: dateRange?.[0] ?? null,
              date_range_end: dateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsDatabaseCostAggregateChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultCostGroupingOption}
                options={costGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {costGroupingLabel[queryParams.cost_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <StackedBarChart
                data={costData}
                disableDrilldown
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                limit={10}
                isLoading={isLoadingCostData}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                emptyPlaceholderText={copyText.noDataPlaceholderMessage}
                xAxisKey="timestamp"
              />
            </Box>
          </Box>
        </Flex>
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsDatabaseUsageChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultUsageGroupingOption}
                options={usageGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {usageGroupingLabel[queryParams.usage_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <AreaChart
                data={usageData}
                dimensions={[]}
                disableDrilldown
                hideTotal={
                  queryParamState.selectedUsageChartOption !==
                  AWSDatabaseUsageChartOption.IOPS
                }
                isLoading={isLoadingUsageData}
                measures={usageMeasures.map((measure) => {
                  return { name: measure };
                })}
                mergeMeasures
                readableKeys={resourceUsageKeys}
                showLegend
                showTooltip
                stacked={
                  queryParamState.selectedUsageChartOption ===
                  AWSDatabaseUsageChartOption.IOPS
                }
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                tooltipFormatter={formatUsageValue}
                xAxisKey="timestamp"
                yAxisFormatter={formatUsageValue}
              />
            </Box>
          </Box>
        </Flex>
      </Grid>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSDatabaseInstanceGroupTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.instanceGroupFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <RDSInstanceGroupTable
        instanceGroups={filteredInstanceGroups}
        isLoading={isLoadingInstanceGroups}
        onInteraction={handleInteraction}
      />
      {queryParamState.selectedInstanceGroup !== null && (
        <AWSDatabaseInstanceTable
          instances={instances}
          isLoading={isLoadingInstances}
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
        />
      )}
    </Box>
  );
}

function byteFormatter(value: unknown, options?: { binary?: boolean }): string {
  if (typeof value !== "number" || !Number.isFinite(value)) {
    return "0";
  }

  return prettyBytes(value, options?.binary ? { binary: true } : undefined);
}

function getCostDimensionsFromOption(option: AWSDatabaseCostChartOption) {
  switch (option) {
    case AWSDatabaseCostChartOption.DATABASE_TYPE:
      return ["databaseType"];
    case AWSDatabaseCostChartOption.INSTANCE:
      return ["instanceId"];
    case AWSDatabaseCostChartOption.FAMILY:
      return ["family"];
    case AWSDatabaseCostChartOption.LINKED_ACCOUNT:
      return ["lineItemUsageAccountId"];
    case AWSDatabaseCostChartOption.PRODUCT_FAMILY:
      return ["productFamily"];
    case AWSDatabaseCostChartOption.REGION:
      return ["region"];
    case AWSDatabaseCostChartOption.STORAGE_TYPE:
      return ["databaseType"];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageMeasuresFromOption(
  option: AWSDatabaseUsageChartOption,
  granularity: TimeGranularity
): string[] {
  if (option === AWSDatabaseUsageChartOption.NETWORK) {
    return ["networkBytes"];
  } else if (option === AWSDatabaseUsageChartOption.CONNECTIONS) {
    return ["databaseConnections"];
  } else if (option === AWSDatabaseUsageChartOption.DISK) {
    return ["usedDiskBytes"];
  } else if (option === AWSDatabaseUsageChartOption.IOPS) {
    return ["readIOPS", "writeIOPS"];
  }

  if (granularity === TimeGranularity.HOUR) {
    if (option === AWSDatabaseUsageChartOption.MEMORY) {
      return ["usedMemoryBytes", "productMemoryBytesMeasure"];
    } else if (option === AWSDatabaseUsageChartOption.CPU) {
      return ["usedVCPU", "productVCPUMeasure"];
    }
  } else {
    if (option === AWSDatabaseUsageChartOption.MEMORY) {
      return ["usedMemoryBytesChart", "productMemoryBytesChart"];
    } else {
      return ["usedVCPUChart", "productVCPUChart"];
    }
  }
  return [];
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "cost", unit: UnitType.CURRENCY },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

function getInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  const groupDimensionKeys = [
    "family",
    "familyType",
    "lineItemUsageAccountId",
    "databaseType",
    "region",
  ] as const;

  const andFilter = groupDimensionKeys.map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );

  return [{ and: andFilter }];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.cost_grouping,
    selectedInstanceGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.usage_grouping,
  };
}

function isInstanceGroupPassingFilters(
  instanceGroup: AWSDatabaseInstanceGroup,
  filters: AWSDatabaseInstanceGroupFilters
): boolean {
  if (
    filters.family !== null &&
    instanceGroup.family.toLowerCase().trim() !==
      filters.family.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.familyType !== null &&
    instanceGroup.familyType.toLowerCase().trim() !==
      filters.familyType.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.lineItemUsageAccountId !== null &&
    instanceGroup.lineItemUsageAccountId.toLowerCase().trim() !==
      filters.lineItemUsageAccountId.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.databaseType !== null &&
    instanceGroup.databaseType.toLowerCase().trim() !==
      filters.databaseType.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.region !== null &&
    instanceGroup.region.toLowerCase().trim() !==
      filters.region.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AWSDatabaseInstanceGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.databaseType,
    instanceGroup.family,
    instanceGroup.familyType,
    instanceGroup.lineItemUsageAccountId,
    instanceGroup.region,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

const csvAccessors = [
  "lineItemUsageAccountId",
  "region",
  "family",
  "familyType",
  "databaseType",
  "instanceCount",
  "instanceUnits",
  "totalCost",
  "productVCPU",
  "productMemoryBytes",
  "networkBytes",
  "usedDiskBytes",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: AWSDatabaseInstanceGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    databaseType: group.databaseType,
    family: group.family,
    familyType: group.familyType,
    instanceCount: group.instanceIDCount,
    instanceUnits: group.instanceUnits,
    lineItemUsageAccountId: group.lineItemUsageAccountId,
    networkBytes: group.networkBytes,
    productMemoryBytes: group.productMemoryBytes,
    productVCPU: group.productVCPU,
    usedDiskBytes: group.usedDiskBytes,
    region: group.region,
    totalCost: group.totalCost,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsDatabaseTableInstanceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AWSDatabaseInstanceGroup[];
  instanceGroupFilters: AWSDatabaseInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AWSDatabaseInstanceGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}
