// Intentionally making these numbers for easy table sorting
export const IntegrationStatusVariant = {
  INCOMPLETE: "1",
  DANGER: "2",
  WARNING: "3",
  SUCCESS: "4",
} as const;

export type IntegrationStatusVariant =
  (typeof IntegrationStatusVariant)[keyof typeof IntegrationStatusVariant];
