import IconAzureAks from "@/ui-lib/icons/IconAzureAks";
import {
  CloudProviderType,
  DataSource,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useGetChangePercent from "../../../hooks/useGetChangePercent";
import IconAwsEks from "../../../ui-lib/icons/IconAwsEks";
import IconGcpKubernetes from "../../../ui-lib/icons/IconGcpKubernetes";
import copyText from "../copyText";
import useGetKubernetesSpendSummaries from "../hooks/useGetKubernetesSpendSummaries";
import NotConfiguredSectionBadge from "./NotConfiguredSectionBadge";
import ServiceSectionBadge from "./ServiceSectionBadge";

export default function KubernetesInsightsLandingPage() {
  const gatekeeper = useGatekeeper();

  //
  // Queries
  //

  const gcpKubernetesSpendSummaries = useGetKubernetesSpendSummaries({
    dataSource: DataSource.KUBERNETES_CLUSTER_USAGE,
  });

  const { data: currentGcpMtdSpend, isLoading: isLoadingCurrentGcpMtdSpend } =
    gcpKubernetesSpendSummaries[0];

  const { data: lastGcpMtdSpend, isLoading: isLoadingLastGcpMtdSpend } =
    gcpKubernetesSpendSummaries[2];

  const gcpMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastGcpMtdSpend?.totalCost ?? 0,
    value: currentGcpMtdSpend?.totalCost ?? 0,
  });

  const awsKubernetesSpendSummaries = useGetKubernetesSpendSummaries({
    dataSource: DataSource.AWS_KUBERNETES_NODE_COST,
  });

  const { data: currentAwsMtdSpend, isLoading: isLoadingCurrentAwsMtdSpend } =
    awsKubernetesSpendSummaries[0];

  const { data: lastAwsMtdSpend, isLoading: isLoadingLastAwsMtdSpend } =
    awsKubernetesSpendSummaries[2];

  const awsMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAwsMtdSpend?.totalCost ?? 0,
    value: currentAwsMtdSpend?.totalCost ?? 0,
  });

  const azureKubernetesSpendSummaries = useGetKubernetesSpendSummaries({
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
  });

  const {
    data: currentAzureMtdSpend,
    isLoading: isLoadingCurrentAzureMtdSpend,
  } = azureKubernetesSpendSummaries[0];

  const { data: lastAzureMtdSpend, isLoading: isLoadingLastAzureMtdSpend } =
    azureKubernetesSpendSummaries[2];

  const azureMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAzureMtdSpend?.totalCost ?? 0,
    value: currentAzureMtdSpend?.totalCost ?? 0,
  });

  //
  // Render
  //

  const isLoadingAWSSpend =
    isLoadingCurrentAwsMtdSpend || isLoadingLastAwsMtdSpend;

  const isLoadingAzureSpend =
    isLoadingCurrentAzureMtdSpend || isLoadingLastAzureMtdSpend;

  const isLoadingGCPSpend =
    isLoadingCurrentGcpMtdSpend || isLoadingLastGcpMtdSpend;

  return (
    <Box>
      {gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">{copyText.awsServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAwsMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAWSSpend}
            mtdSpendComparison={awsMtdSpendComparison}
            path={paths._insightsKubernetesEKS}
            serviceIcon={<IconAwsEks size={50} />}
            serviceName={copyText.awsKubernetesCloudTitle}
          />
        </>
      )}
      {gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">{copyText.azureServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAzureMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAzureSpend}
            mtdSpendComparison={azureMtdSpendComparison}
            path={paths._insightsKubernetesAKS}
            serviceIcon={<IconAzureAks size={50} />}
            serviceName={copyText.azureKubernetesCloudTitle}
          />
        </>
      )}
      {gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">{copyText.gcpServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentGcpMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingGCPSpend}
            mtdSpendComparison={gcpMtdSpendComparison}
            path={paths._insightsKubernetesGKE}
            serviceIcon={<IconGcpKubernetes size={50} />}
            serviceName={copyText.gcpKubernetesCloudTitle}
          />
        </>
      )}

      {!gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">
            {copyText.awsServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsEks size={50} />}
            serviceName={copyText.awsKubernetesCloudTitle}
          />
        </>
      )}
      {!gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">
            {copyText.awsServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AZURE}
            serviceIcon={<IconAzureAks size={50} />}
            serviceName={copyText.azureKubernetesCloudTitle}
          />
        </>
      )}
      {!gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">
            {copyText.gcpServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.GCP}
            serviceIcon={<IconGcpKubernetes size={50} />}
            serviceName={copyText.gcpKubernetesCloudTitle}
          />
        </>
      )}
    </Box>
  );
}
