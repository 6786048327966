import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type RoleEntity = Awaited<
  ReturnType<CoreAPIClient["getRolesByTenantID"]>
>[number];

export default function useGetRolesByTenantID(
  tenantID: string,
  options?: UseQueryOptions<RoleEntity[], Error>
): UseQueryResult<RoleEntity[], Error> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["roles", tenantID],
    queryFn: () => client.getRolesByTenantID(tenantID),
    ...options,
  });
}
